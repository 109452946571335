<template>
  <AppAjaxContent :is-loading="!data">
    <AppForm v-if="data" v-bind="mileageFormProps" v-on="formEvents">
      <template #group="{id, errors}">
        <template v-if="id === 'give_mileage_expires_from_soldout'">
          <div class="AppForm__group-field">
            <AppSelectRadio
              v-model="formObject.give_mileage_expires_from_soldout"
              :options="GiveMileageExpiresFromSoldoutType.options()"
              name="mileage_settings[give_mileage_expires_from_soldout]"
            >
              <template #label="{ value, checked }">
                <template
                  v-if="value === GiveMileageExpiresFromSoldoutType.NDAYS"
                >
                  <i18n path="GiveMileageExpiresFromSoldoutType.NDAYS">
                    <AppNumberInput
                      v-model="
                        formObject.give_mileage_expires_after_days_from_soldout
                      "
                      :disabled="!checked"
                      name="mileage_settings[give_mileage_expires_after_days_from_soldout]"
                      :digits="2"
                      :invalid="
                        !!errors.give_mileage_expires_after_days_from_soldout
                      "
                      @blur="
                        validateField(
                          'give_mileage_expires_after_days_from_soldout'
                        )
                      "
                      @change="
                        validateField(
                          'give_mileage_expires_after_days_from_soldout'
                        )
                      "
                    />
                  </i18n>
                  <AppFormError
                    :error="errors.give_mileage_expires_after_days_from_soldout"
                  />
                </template>
              </template>
            </AppSelectRadio>
          </div>
        </template>
        <template v-else-if="id === 'limit_give_mileage_each_sub_order'">
          <div class="AppForm__group-field">
            <AppSelectRadio
              v-model="formObject.maxMileageType"
              :options="maxMileageTypeOptions"
              @change="changeMaxMileageType"
            >
              <template #sub-item="{ value, checked }">
                <template v-if="value === 'unlimited'">
                  <input
                    type="hidden"
                    name="mileage_settings[maximum_mileage_type]"
                    :disabled="!checked"
                    :value="formObject.maximum_mileage_type"
                  />
                  <input
                    type="hidden"
                    name="mileage_settings[max_mileage_amount_per_order_cents]"
                    value="0"
                  />
                  <input
                    type="hidden"
                    name="mileage_settings[max_mileage_sub_orders_count_per_order]"
                    value="0"
                  />
                </template>
                <template v-else-if="value === 'mileage'">
                  <input
                    type="hidden"
                    name="mileage_settings[maximum_mileage_type]"
                    :disabled="!checked"
                    :value="MaximumMileageType.MILEAGE"
                  />
                  <i18n path="maximum_mileage_type_amount">
                    <AppCurrencyInput
                      v-model="formObject.max_mileage_amount_per_order_cents"
                      :disabled="!checked"
                      name="mileage_settings[max_mileage_amount_per_order_cents]"
                      :digits="4"
                      :invalid="!!errors.max_mileage_amount_per_order_cents"
                      @blur="
                        validateField('max_mileage_amount_per_order_cents')
                      "
                      @change="
                        validateField('max_mileage_amount_per_order_cents')
                      "
                    />
                  </i18n>
                  <AppFormError
                    :error="errors.max_mileage_amount_per_order_cents"
                  />
                </template>
                <template v-else>
                  <input
                    type="hidden"
                    name="mileage_settings[maximum_mileage_type]"
                    :disabled="!checked"
                    :value="MaximumMileageType.SUB_ORDERS_COUNT"
                  />
                  <i18n path="maximum_mileage_type_product">
                    <AppNumberInput
                      v-model="
                        formObject.max_mileage_sub_orders_count_per_order
                      "
                      :disabled="!checked"
                      name="mileage_settings[max_mileage_sub_orders_count_per_order]"
                      :digits="4"
                      :invalid="!!errors.max_mileage_sub_orders_count_per_order"
                      @blur="
                        validateField('max_mileage_sub_orders_count_per_order')
                      "
                      @change="
                        validateField('max_mileage_sub_orders_count_per_order')
                      "
                    />
                  </i18n>
                  <AppFormError
                    :error="errors.max_mileage_sub_orders_count_per_order"
                  />
                </template>
              </template>
            </AppSelectRadio>
          </div>
        </template>
        <template v-else-if="id === 'basic_mileage_criteria'">
          <AppSelectButton
            v-model="formObject.give_mileage_default_type"
            class="AppForm__group-field"
            name="mileage_settings[give_mileage_default_type]"
            :options="mileageTypeOptions"
          />
          <div
            v-for="(datum, i) in formObject.basic_mileage_data"
            :key="datum.id"
          >
            <div class="AppForm__group-field AppForm__group-field--mt16">
              <label class="AppForm__group-title">
                <template v-if="i === 0">
                  <i18n path="basic_mileage_criteria.min_message_length_above">
                    <AppNumberInput
                      v-model="datum.minimum_message_length"
                      :name="
                        `mileage_settings[auto_mileage_basic_settings_attributes][${i}][minimum_message_length]`
                      "
                      :digits="3"
                      :invalid="!!errors[`${datum.id}[minimum_message_length]`]"
                      @blur="
                        validateField(`${datum.id}[minimum_message_length]`)
                      "
                      @change="validateBasicMileages"
                    />
                  </i18n>
                  <AppFormError
                    :error="errors[`${datum.id}[minimum_message_length]`]"
                  />
                </template>
                <template
                  v-else-if="i < formObject.basic_mileage_data.length - 1"
                >
                  <i18n
                    path="basic_mileage_criteria.min_message_length_between"
                  >
                    <AppNumberInput
                      v-model="datum.minimum_message_length"
                      :name="
                        `mileage_settings[auto_mileage_basic_settings_attributes][${i}][minimum_message_length]`
                      "
                      :digits="3"
                      :invalid="!!errors[`${datum.id}[minimum_message_length]`]"
                      @blur="
                        validateField(`${datum.id}[minimum_message_length]`)
                      "
                      @change="validateBasicMileages"
                    />
                    <template>{{
                      formObject.basic_mileage_data[i - 1]
                        .minimum_message_length
                    }}</template>
                  </i18n>
                  <AppFormError
                    :error="errors[`${datum.id}[minimum_message_length]`]"
                  />
                </template>
                <template v-else>
                  {{
                    $t('basic_mileage_criteria.min_message_length_below', [
                      formObject.basic_mileage_data[i - 1]
                        .minimum_message_length
                    ])
                  }}
                </template>
              </label>
            </div>
            <div class="AppForm__group-field AppForm__group-field--mt8">
              <Component
                :is="
                  formObject.give_mileage_default_type === 'product_price'
                    ? 'ReviewSettingsMileageAmountProductPriceTable'
                    : formObject.give_mileage_default_type ===
                      'review_written_order'
                    ? 'ReviewSettingsMileageAmountReviewWrittenOrderTable'
                    : 'ReviewSettingsMileageAmountPercentageOfProductPriceTable'
                "
                :datum="datum"
                :name="
                  `mileage_settings[auto_mileage_basic_settings_attributes][${i}]`
                "
                :errors="errors"
                @validate-field="validateField($event)"
                @change="$set(datum, $event.key, $event.value)"
              />
              <AppFormError
                :error="errors[`${datum.id}[order_product_price]`]"
              />
            </div>
          </div>
          <div class="AppForm__group-field AppForm__group-field--mt16">
            <AppButton
              :label="$t('basic_mileage_criteria.add_button')"
              @click="addCriterion"
            />
            <AppButton
              v-if="formObject.basic_mileage_data.length > 1"
              button-style="red-outline"
              :label="$t('basic_mileage_criteria.remove_button')"
              @click="removeLastCriterion"
            />
          </div>
        </template>
        <template v-else-if="id === 'give_mileage_nth_photo_reviews'">
          <div
            v-for="(item, i) in formObject.nth_photo_reviews"
            :key="item.id"
            class="AppForm__group-field"
          >
            <i18n path="give_mileage_nth_photo_reviews.input">
              <template>{{ i + 1 }}</template>
              <AppCurrencyInput
                v-model="item.value"
                name="mileage_settings[give_mileage_nth_photo_reviews][]"
                :digits="6"
                :default="0"
              />
            </i18n>
          </div>
          <div class="AppForm__group-field AppForm__group-field--mt16">
            <AppButton
              :label="$t('add_nth_photo_review')"
              @click="addNthPhotoReview"
            />
            <AppButton
              v-if="formObject.nth_photo_reviews.length > 1"
              button-style="red-outline"
              :label="$t('remove_nth_photo_review')"
              @click="formObject.nth_photo_reviews.pop()"
            />
          </div>
        </template>
        <template v-else-if="id === 'auto_mileage_user_grade_bonuses'">
          <div class="AppForm__group-field AppForm__group-field--mt8">
            <input
              type="hidden"
              name="mileage_settings[auto_mileage_user_grade_bonuses_json]"
              :value="
                JSON.stringify(formObject.auto_mileage_user_grade_bonuses)
              "
            />
            <ReviewSettingsMileageAmountUserGradeBonusTable
              v-model="formObject.auto_mileage_user_grade_bonuses"
              :user-grades="data.brand_user_grades"
              :errors="errors"
              @validate-field="validateField($event)"
            />
          </div>
        </template>
        <template v-else-if="id === 'auto_mileage_by_prices'">
          <div class="AppForm__group-field AppForm__group-field--mt8">
            <input
              v-if="!formObject.price_bonuses.length"
              type="hidden"
              name="mileage_settings[auto_mileage_by_prices][]"
            />
            <ReviewSettingsMileageAmountPriceBonusTable
              v-model="formObject.price_bonuses"
              :errors="errors"
              @validate-field="validateField($event)"
            />
          </div>
        </template>
      </template>
    </AppForm>
  </AppAjaxContent>
</template>

<script>
import _ from 'lodash';
import api from '@/lib/api';
import { mapActions } from 'vuex';
import { mapState, mapGetters } from 'vuex';
import FormView from '@/mixins/FormView';
import SettingsView from '@/mixins/SettingsView';
import ReviewPointType from '@/enums/ReviewPointType';
import ReviewAllowedAuthorType from '@/enums/ReviewAllowedAuthorType';
import GiveMileageExpiresFromSoldoutType from '@/enums/GiveMileageExpiresFromSoldoutType';
import MaximumMileageType from '@/enums/MaximumMileageType';
import ActionForInsufficientReviewLengthToGetMileageType from '@/enums/ActionForInsufficientReviewLengthToGetMileageType';
import ReviewSettingsMileageAmountUserGradeBonusTable from './ReviewSettingsMileageAmountUserGradeBonusTable';
import ReviewSettingsMileageAmountPriceBonusTable from './ReviewSettingsMileageAmountPriceBonusTable';
import ReviewSettingsMileageAmountProductPriceTable from './ReviewSettingsMileageAmountProductPriceTable';
import ReviewSettingsMileageAmountReviewWrittenOrderTable from './ReviewSettingsMileageAmountReviewWrittenOrderTable';
import ReviewSettingsMileageAmountPercentageOfProductPriceTable from './ReviewSettingsMileageAmountPercentageOfProductPriceTable';
import ReviewSettingsMileageAmountSimpleProductPriceTable from './ReviewSettingsMileageAmountSimpleProductPriceTable';

export default {
  name: 'ReviewSettingsMileage',
  components: {
    ReviewSettingsMileageAmountUserGradeBonusTable,
    ReviewSettingsMileageAmountPriceBonusTable,
    ReviewSettingsMileageAmountProductPriceTable,
    ReviewSettingsMileageAmountReviewWrittenOrderTable,
    ReviewSettingsMileageAmountPercentageOfProductPriceTable,
    ReviewSettingsMileageAmountSimpleProductPriceTable
  },
  mixins: [FormView, SettingsView],
  data() {
    return {
      SETTINGS_ID: 'review_settings_mileage',
      data: null
    };
  },
  computed: {
    ...mapState('session', ['currentBrand', 'brandSettings']),
    ...mapGetters('session', ['isNthStepReviewCampaignEnabled']),
    mileageFormProps() {
      return {
        ...this.formProps,
        id: this.SETTINGS_ID,
        submitButton: true,
        objectId: 'mileage_settings',
        formStyle: 'hor'
      };
    },
    groupOptions() {
      const {
        allowed_author_type,
        allowed_days_from_delivery,
        auto_mileage_user_grade_bonuses,
        price_bonuses,
        basic_mileage_data,
        give_mileage_default_type,
        nth_step_review_mileage_expiry_days
      } = this.formObject;

      return {
        review_point_type: {
          type: 'select_radio',
          options: ReviewPointType.options(this.reviewPointTypes)
        },
        give_mileage_expires_after_days: {
          // TODO: n차 리뷰 캠페인 오픈 후 삭제 및 locale문구 수정
          ...(this.isNthStepReviewCampaignEnabled
            ? {
                label: this.$t('renewed_give_mileage_expires_after_days')
              }
            : {}),
          type: 'number',
          digits: 2,
          i18n:
            'review_settings_mileage_condition.give_mileage_expires_after_days',
          validate: [
            'required',
            'positive_integer',
            [
              ReviewAllowedAuthorType.BUYER_ONLY,
              ReviewAllowedAuthorType.DELIVERY_FINISHED_BUYER_ONLY
            ].includes(allowed_author_type) && allowed_days_from_delivery > 0
              ? {
                  rule: 'lte',
                  allowed: allowed_days_from_delivery,
                  errorMessage: this.$t(
                    'give_mileage_expires_after_days_must_be_lte_allowed_days',
                    [allowed_days_from_delivery]
                  )
                }
              : null
          ].filter(Boolean)
        },
        nth_step_review_mileage_expiry_days: {
          type: 'number',
          value: nth_step_review_mileage_expiry_days,
          digits: 4,
          i18n:
            'review_settings_mileage_condition.nth_step_review_mileage_expiry_days',
          validate: [
            'required',
            'positive_integer',
            {
              rule: 'min_max_integer',
              min: 1,
              max: 9999
            }
          ]
        },
        give_mileage_expires_from_soldout: {
          fields:
            this.formObject.give_mileage_expires_from_soldout ===
            GiveMileageExpiresFromSoldoutType.NDAYS
              ? [
                  {
                    id: 'give_mileage_expires_after_days_from_soldout',
                    validate: ['required', 'positive_integer']
                  }
                ]
              : undefined
        },
        add_coupon_mileage_to_total_price: {
          type: 'select_radio',
          options: [
            {
              label: this.$t('add_coupon_mileage_to_total_price.inclusion'),
              value: true
            },
            {
              label: this.$t('add_coupon_mileage_to_total_price.exclusion'),
              value: false
            }
          ],
          hint: this.$t('add_coupon_mileage_to_total_price.hint')
        },
        give_mileage_exceptions: {
          type: 'hash_select_checkbox',
          options: this.giveMileageExceptionsOptions.map(id => ({
            id,
            label: this.$t(`give_mileage_exceptions.${id}`)
          }))
        },
        mileage_callback_url: {
          type: 'text',
          value: this.formObject.mileage_callback_url,
          groupDescription: this.$t('mileage_callback_url.description')
        },
        auto_mileage_required_review_option_type_ids: this.formObject
          .use_product_category_product_type
          ? {
              type: 'button',
              value: this.$t(
                'auto_mileage_required_review_option_type_ids_button'
              ),
              clickHandler: this.openOptionTypeSetSettingsDialog
            }
          : {
              type: 'multiple_select',
              options: this.formObject.review_option_types.map(t => ({
                label: t.name,
                value: t.id
              })),
              placeholder: this.$t(
                'auto_mileage_required_review_option_type_ids_placeholder'
              )
            },
        auto_mileage_excluding_category_ids: {
          type: 'select_product_category',
          multiple: true
        },
        auto_mileage_excluding_product_ids: {
          type: 'select_product',
          multiple: true,
          products: this.formObject.auto_mileage_excluding_products,
          eventHandlers: {
            'change-items': products =>
              this.$set(
                this.formObject,
                'auto_mileage_excluding_products',
                products
              )
          }
        },
        auto_mileage_excluding_user_grade_ids: {
          type: 'multiple_select',
          options: this.data.brand_user_grades.map(b => ({
            label: b.name,
            value: b.id
          })),
          placeholder: this.$t(
            'auto_mileage_excluding_user_grade_ids_placeholder'
          )
        },
        basic_mileage_criteria: {
          fields: _.flatten(
            basic_mileage_data.map((datum, i) => {
              const fields = [];
              if (i === 0)
                fields.push({
                  id: `${datum.id}[minimum_message_length]`,
                  value: datum.minimum_message_length,
                  validate: ['required', 'positive_integer']
                });
              else if (i < basic_mileage_data.length - 1)
                fields.push({
                  id: `${datum.id}[minimum_message_length]`,
                  value: datum.minimum_message_length,
                  validate: [
                    'required',
                    'positive_integer',
                    {
                      rule: v =>
                        v < basic_mileage_data[i - 1].minimum_message_length,
                      errorMessage: this.$t('must_be_lt_prev')
                    }
                  ]
                });

              if (give_mileage_default_type === 'product_price') {
                fields.push({
                  id: `${datum.id}[order_product_price]`,
                  validate: [
                    {
                      rule: () => {
                        return (
                          this.formObject.basic_mileage_data[i]
                            .auto_mileage_minimum_order_total_price_cents >=
                          this.formObject.basic_mileage_data[i]
                            .auto_mileage_product_price_cents
                        );
                      },
                      errorMessage: this.$t(
                        'basic_mileage_criteria.product_price_must_be_lte_minimum_order_total_price'
                      )
                    }
                  ]
                });
              } else if (
                give_mileage_default_type === 'percentage_of_product_price'
              ) {
                fields.push({
                  id: `${datum.id}[auto_mileage_text_review_percentage_of_product_price]`,
                  value:
                    datum.auto_mileage_text_review_percentage_of_product_price,
                  validate: [{ rule: 'min_max_integer', min: 0, max: 100 }]
                });
                fields.push({
                  id: `${datum.id}[auto_mileage_photo_review_percentage_of_product_price]`,
                  value:
                    datum.auto_mileage_photo_review_percentage_of_product_price,
                  validate: [{ rule: 'min_max_integer', min: 0, max: 100 }]
                });
              }

              return fields;
            })
          )
        },
        auto_mileage_use_valid_review_message_length: {
          type: 'select_radio',
          options: [
            {
              label: this.$t(
                'auto_mileage_use_valid_review_message_length.all_message'
              ),
              value: false
            },
            {
              label: this.$t(
                'auto_mileage_use_valid_review_message_length.valid_message'
              ),
              value: true
            }
          ]
        },
        advanced_tip_box: {
          type: 'tip_box',
          label: '',
          value: this.$t('review_settings_advanced_tip_box.text')
        },
        action_for_insufficient_review_length_to_get_mileage: {
          label: this.$t(
            'action_for_insufficient_review_length_to_get_mileage_label',
            [this.minMessageLength]
          ),
          type: 'select_radio',
          options: ActionForInsufficientReviewLengthToGetMileageType.options()
        },
        mileage_first_review: {
          type: 'currency',
          digits: 6,
          default: 0,
          i18n: 'review_settings_mileage.mileage_first_review'
        },
        give_mileage_to_video_review_cents: {
          type: 'currency',
          digits: 6,
          default: 0,
          i18n: 'review_settings_mileage.give_mileage_to_video_review_cents'
        },
        give_mileage_to_best_review_cents: {
          type: 'currency',
          digits: 6,
          default: 0,
          i18n: 'review_settings_mileage.give_mileage_to_best_review_cents',
          description: this.$t('give_mileage_to_best_review_cents.description')
        },
        nth_step_review_additional_mileage_cents: {
          type: 'currency',
          digits: 8,
          default: 0,
          i18n:
            'review_settings_mileage.nth_step_review_additional_mileage_cents',
          validate: [{ rule: 'min_max_integer', min: 0, max: 99999999 }]
        },
        manual_mileage_max_amount_cents: {
          type: 'currency',
          digits: 6,
          default: 0,
          i18n: 'review_settings_mileage.manual_mileage_max_amount_cents'
        },
        auto_mileage_user_grade_bonuses: {
          fields: _.map(auto_mileage_user_grade_bonuses, (amount, id) => ({
            id: `auto_mileage_user_grade_bonuses[${id}]`,
            value: amount
          }))
        },
        auto_mileage_by_prices: {
          fields: _.flatten(
            price_bonuses.map((item, i) => [
              {
                id: `auto_mileage_by_prices[${item.id}]`,
                value: item.price,
                validate: [
                  i > 0
                    ? {
                        rule: v => v < price_bonuses[i - 1].price,
                        errorMessage: this.$t('must_be_lt_prev')
                      }
                    : null
                ].filter(v => v)
              }
            ])
          )
        },
        give_mileage_to_include_link_review_cents: {
          type: 'currency',
          digits: 6,
          default: 0,
          i18n:
            'review_settings_mileage.give_mileage_to_include_link_review_cents',
          description: this.$t(
            'give_mileage_to_include_link_review_cents.description'
          )
        }
      };
    },
    GiveMileageExpiresFromSoldoutType: () => GiveMileageExpiresFromSoldoutType,
    MaximumMileageType: () => MaximumMileageType,
    reviewPointTypes() {
      const { shop_builder } = this.currentBrand;
      switch (shop_builder) {
        case 'cafe24':
        case 'wisa':
          return [ReviewPointType.MILEAGE, ReviewPointType.COUPON];
        case 'makeshop':
          return [
            ReviewPointType.MILEAGE,
            ReviewPointType.COUPON,
            ReviewPointType.POINT
          ];
        case 'shopify':
          return [ReviewPointType.DISCOUNT_CODE];
        default:
          return [ReviewPointType.MILEAGE];
      }
    },
    simpleBasicMileageCriteriaData() {
      return this.formObject.basic_mileage_data[0];
    },
    giveMileageExceptionsOptions() {
      const { shop_builder } = this.currentBrand;
      const { shop_builder_admin_version } = this.brandSettings;
      if (
        shop_builder === 'makeshop' ||
        (shop_builder === 'cafe24' && shop_builder_admin_version === 3)
      ) {
        return [
          'give_mileage_to_refund_order',
          'give_mileage_to_order_discounted_price',
          'give_mileage_to_exchanged_order',
          'give_mileage_to_order_discounted_with_coupon',
          'give_mileage_to_order_discounted_with_mileage',
          'auto_mileage_same_product_in_order'
        ];
      } else if (shop_builder === 'shopify') {
        return [
          'give_mileage_to_refund_order',
          'auto_mileage_same_product_in_order'
        ];
      }
      return [
        'give_mileage_to_refund_order',
        'give_mileage_to_order_discounted_price',
        'give_mileage_to_exchanged_order',
        'auto_mileage_same_product_in_order'
      ];
    },
    mileageTypeOptions() {
      return [
        'product_price',
        'review_written_order',
        'percentage_of_product_price'
      ].map(value => ({
        value,
        label: this.$t(`mileage_type.${value}`)
      }));
    },
    maxMileageTypeOptions() {
      return ['unlimited', 'mileage', 'sub_orders_count'].map(value => ({
        label: this.$t(`maximum_mileage_type.${value}`),
        value
      }));
    },
    minMessageLength() {
      const { basic_mileage_data } = this.formObject;
      return basic_mileage_data[Math.max(basic_mileage_data.length - 2, 0)]
        .minimum_message_length;
    },
    isAlertMileageRecalculationNeeded() {
      const diffObject = _.cloneDeep(this.changedFormObject);
      const keysToIgnoreAlert = ['manual_mileage_max_amount_cents'];
      keysToIgnoreAlert.forEach(key => delete diffObject[key]);

      return !_.isEmpty(diffObject);
    },
    changedFormObject() {
      return _.fromPairs(
        _.differenceWith(
          _.toPairs(this.orgFormObject),
          _.toPairs(this.formObject),
          _.isEqual
        )
      );
    }
  },
  mounted() {
    api.get('/review/mileage_settings').then(({ data }) => {
      this.data = data;
      this.setOrgFormObject(data.mileage_settings);
    });
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    ...mapActions('toast', ['createToast']),
    ...mapActions('session', ['updateReviewSettings']),
    submit(formData) {
      this.submitMileageSettings(formData);
    },
    submitMileageSettings(formData) {
      this.isSubmitting = true;
      api
        .patch('/review/mileage_settings', formData)
        .then(() => {
          if (this.isAlertMileageRecalculationNeeded) {
            alert(
              this.$t('ReviewSettingsView.mileage_recalculation_alert_message')
            );
          } else this.createToast(this.$t('app.saved'));

          this.setOrgFormObject(this.formObject);
          this.updateReviewSettings(this.reviewSettings);
        })
        .finally(() => (this.isSubmitting = false));
    },
    setOrgFormObject(formObject) {
      this.orgFormObject = _.cloneDeep(formObject);

      const {
        maximum_mileage_type,
        max_mileage_amount_per_order_cents,
        max_mileage_sub_orders_count_per_order
      } = formObject;

      const maxMileageType =
        (maximum_mileage_type === MaximumMileageType.MILEAGE &&
          max_mileage_amount_per_order_cents === 0) ||
        (maximum_mileage_type === MaximumMileageType.SUB_ORDERS_COUNT &&
          max_mileage_sub_orders_count_per_order === 0)
          ? 'unlimited'
          : maximum_mileage_type === MaximumMileageType.MILEAGE
          ? 'mileage'
          : 'sub_orders_count';

      this.orgFormObject.maxMileageType ||= maxMileageType;

      this.orgFormObject.basic_mileage_data =
        this.orgFormObject.basic_mileage_data ||
        formObject.auto_mileage_basic_settings.map(datum => ({
          ...datum,
          id: _.uniqueId('basic_mileage')
        }));

      this.orgFormObject.nth_photo_reviews =
        this.orgFormObject.nth_photo_reviews ||
        formObject.give_mileage_nth_photo_reviews.map(value => ({
          id: _.uniqueId('nth_photo_review'),
          value
        }));

      this.orgFormObject.price_bonuses =
        this.orgFormObject.price_bonuses ||
        formObject.auto_mileage_by_prices.map(item => ({
          id: _.uniqueId('price_bonus'),
          ...item
        }));
    },
    openOptionTypeSetSettingsDialog() {
      this.openDialog('ReviewSettingsOptionTypeSetDialog');
    },
    addCriterion() {
      this.formObject.basic_mileage_data.push(this.newCriterion());
    },
    removeLastCriterion() {
      this.formObject.basic_mileage_data.pop();
    },
    newCriterion() {
      return {
        id: _.uniqueId('basic_mileage'),
        minimum_message_length: 0,
        auto_mileage_minimum_order_total_price_cents: 0,
        auto_mileage_product_price_cents: 0,
        auto_mileage_text_review_upper_product_price_cents: 0,
        auto_mileage_photo_review_upper_product_price_cents: 0,
        auto_mileage_text_review_lower_product_price_cents: 0,
        auto_mileage_photo_review_lower_product_price_cents: 0,
        auto_mileage_first_text_review_in_order_cents: 0,
        auto_mileage_first_photo_review_in_order_cents: 0,
        auto_mileage_not_first_text_review_in_order_cents: 0,
        auto_mileage_not_first_photo_review_in_order_cents: 0,
        auto_mileage_text_review_percentage_of_product_price: 0,
        auto_mileage_photo_review_percentage_of_product_price: 0
      };
    },
    addNthPhotoReview() {
      this.formObject.nth_photo_reviews.push({
        id: _.uniqueId('nth_photo_review'),
        value: 0
      });
    },
    validateBasicMileages() {
      this.formObject.basic_mileage_data.forEach(({ id }) =>
        this.validateField(`${id}[minimum_message_length]`)
      );
    },
    changeMaxMileageType(maxMileageType) {
      this.formObject.maximum_mileage_type =
        maxMileageType === 'sub_orders_count'
          ? MaximumMileageType.SUB_ORDERS_COUNT
          : MaximumMileageType.MILEAGE;
    }
  }
};
</script>

<i18n locale="ko">
{
  "renewed_give_mileage_expires_after_days": "최초 리뷰 적립금 지급 기간",
  "add_nth_photo_review": "조건 추가",
  "remove_nth_photo_review": "마지막 조건 삭제",
  "give_mileage_expires_after_days_must_be_lte_allowed_days": "'기본 설정' 메뉴의 '리뷰 작성 대상' 설정값 {0}일보다 작거나 같은 값을 입력하세요.",
  "add_coupon_mileage_to_total_price": {
    "inclusion": "주문 총액에 쿠폰, 적립금 사용금액 포함",
    "exclusion": "주문 총액에 쿠폰, 적립금 사용금액 제외",
    "hint": "독립몰은 order API의 mileage 옵션을 사용할 경우에만 설정 가능합니다."
  },
  "mileage_callback_url": {
    "description": "위사, 비즈호스트, 자체제작/기타인 경우, 크리마 관리자 페이지에서 적립금 지급 시 쇼핑몰로 지급 요청을 하는 URL입니다."
  },
  "give_mileage_exceptions": {
    "give_mileage_to_refund_order": "반품 및 환불 상품",
    "give_mileage_to_exchanged_order": "교환 상품",
    "give_mileage_to_order_discounted_price": "할인 상품",
    "give_mileage_to_order_discounted_with_coupon": "구매 시, 쿠폰을 사용한 상품",
    "give_mileage_to_order_discounted_with_mileage": "구매 시, 적립금을 사용한 상품",
    "auto_mileage_same_product_in_order": "한 주문건 내 동일 상품 (옵션상관없음)"
  },
  "action_for_insufficient_review_length_to_get_mileage_label": "{0} 글자 미만일 경우 리뷰 작성 허용 여부",
  "auto_mileage_required_review_option_type_ids_placeholder": "추가정보를 선택해주세요.",
  "auto_mileage_required_review_option_type_ids_button": "적립금 지급을 위한 필수 추가 정보 설정",
  "auto_mileage_excluding_user_grade_ids_placeholder": "회원등급을 선택해주세요.",
  "auto_mileage_use_valid_review_message_length": {
    "all_message":"모든 글자 허용 (띄어쓰기 포함)",
    "valid_message":"유효 글자만 허용 (자음, 모음, 띄어쓰기, 반복 문구 제외)"
  },
  "give_mileage_nth_photo_reviews": {
    "input": "상품별 {0}번째 포토/동영상 리뷰일 때, {1} 추가 지급"
  },
  "give_mileage_to_best_review_cents": {
    "description": "실제 지급되는 적립금이 아닌, 작성 팝업에 안내하는 최대 적립금 계산 시 사용됩니다."
  },
  "give_mileage_to_include_link_review_cents": {
    "description": "실제 유효한 링크인지는 직접 확인해야 합니다."
  },
  "basic_mileage_criteria": {
    "min_message_length_above": "{0} 글자 이상일 경우,",
    "min_message_length_between": "{0} 글자 이상 {1} 글자 미만일 경우,",
    "min_message_length_below": "{0} 글자 미만일 경우,",
    "product_price_must_be_lte_minimum_order_total_price": "구매 당시 상품 가격은 주문 총액보다 같거나 작아야 합니다.",
    "add_button": "글자 수 기준으로 조건 추가",
    "remove_button": "마지막 조건 삭제"
  },
  "mileage_type": {
    "product_price": "상품 가격별",
    "review_written_order": "작성 순서별",
    "percentage_of_product_price": "구매가의 %"
  },
  "maximum_mileage_type": {
    "unlimited": "제한 없음",
    "mileage": "최대 적립금",
    "sub_orders_count": "적립금 지급 최대 상품 개수"
  },
  "maximum_mileage_type_amount": "{0} 까지 지급",
  "maximum_mileage_type_product": "{0} 개까지 지급",
  "must_be_lt_prev": "이전 항목 입력값보다 작은 값을 입력해주세요.",
  "confirm_title": "적립금 설정 타입을 변경하시겠습니까?",
  "confirm_text": "변경할 경우 기본 적립금 기준 설정이 초기화됩니다. 변경하시겠습니까?"
}
</i18n>

<i18n locale="en">
{
  "maximum_mileage_type_amount": "Reward up to {0}",
  "maximum_mileage_type_product": "Reward up to {0}",
  "give_mileage_nth_photo_reviews": {
    "input": "Reward additional {1} points, when submitting photo review for the {0}th time per product"
  },
  "basic_mileage_criteria": {
    "min_message_length_above": "when above {0} characters",
    "min_message_length_between": "when it is above {0} characters and below {1} characters",
    "min_message_length_below": "when it is below {0} characters"
  }
}
</i18n>
