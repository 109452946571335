<template>
  <div class="SlotPreview">
    <div class="SlotPreview__header">
      <div class="SlotPreview__title" v-text="$t('preview')" />
      <AppSelectButton
        class="SlotPreview__device"
        :value="WidgetDeviceType.PC"
        :options="
          WidgetDeviceType.options([
            WidgetDeviceType.PC,
            WidgetDeviceType.MOBILE
          ])
        "
      />
    </div>
    <div class="SlotPreview__preview" />
  </div>
</template>

<script>
import WidgetDeviceType from '@/enums/WidgetDeviceType';

export default {
  name: 'SlotPreview',
  computed: {
    WidgetDeviceType() {
      return WidgetDeviceType;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.SlotPreview {
  background-color: #f9f9fb;
  height: 100%;
  width: 100%;
}

.SlotPreview__header {
  display: flex;
  align-items: center;
  padding: 16px 0 0 22px;
}

.SlotPreview__title {
  @include text-sub-title;
}

.SlotPreview__device {
  margin-left: 15px;
}

$header-height: 50px;
$preview-margin: 32px;
.SlotPreview__preview {
  margin: $preview-margin 22px $preview-margin 22px;
  background-color: $color-background;
  border-radius: 8px;
  height: calc(100% - $header-height - ($preview-margin * 2));
}
</style>

<i18n locale="ko">
{
  "preview": "위젯 미리보기"
}
</i18n>
