<template>
  <AppTable
    class="SlotTable AppForm__group-field AppForm__group-field--mt8"
    :columns="columns"
    :rows="rows"
  >
    <template #cell="{ column, row }">
      <template v-if="column.id === 'position'">
        {{ row.position || '-' }}
      </template>
      <template v-else-if="column.id === 'widget_style'">
        <span>{{ row.widget_style_name }}</span>
        <AppBadge
          v-if="row.widget_id"
          class="SlotTable__badge"
          badge-style="mint-green-outline"
          badget-size="small"
          :label="$t('using_widget')"
        />
      </template>
      <template v-else-if="column.id === 'select'">
        <AppSwitch
          :value="row.id"
          :checked="slotInstallations.includes(row.id)"
          @change="updateWidgets(row, $event)"
        />
      </template>
      <template v-else-if="column.id === 'actions'">
        <AppButtonDraggable
          :label="$t('app.sort')"
          :disabled="!isRowDraggable(row.id)"
          @sort="sort"
        />
      </template>
    </template>
  </AppTable>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { arrayMoveIndex } from '@/lib/array';
import InstallRequestRenewalSlotTableView from '@/mixins/InstallRequestRenewalSlotTableView';

export default {
  name: 'SlotTable',
  mixins: [InstallRequestRenewalSlotTableView],
  props: {
    slotType: { type: Number, required: true },
    pageType: { type: Number, required: true }
  },
  data() {
    return {
      rows: [],
      slotInstallations: []
    };
  },
  computed: {
    ...mapState('installRequest', ['installations']),
    columns() {
      return [
        {
          id: 'position',
          label: this.$t('form.position'),
          width: '15%'
        },
        {
          id: 'widget_style',
          label: this.$t('form.widget_style'),
          width: '25%'
        },
        {
          id: 'widget_info',
          type: 'html',
          label: this.$t('form.widget_info'),
          width: '30%'
        },
        {
          id: 'select',
          label: this.$t('app.select'),
          width: '10%'
        },
        {
          id: 'actions',
          label: this.$t('app.actions'),
          width: '20%'
        }
      ];
    },
    defaultSlotInstallationIds() {
      const ids = this.installations
        .filter(
          i =>
            this.pageType === i.page_type &&
            this.slotType === i.slot_type &&
            this.deviceType === i.widget_device_type
        )
        .map(i => i.id);
      return ids;
    }
  },
  methods: {
    ...mapMutations('installRequest', [
      'APPEND_INSTALLATION',
      'REMOVE_INSTALLATION',
      'UPDATE_INSTALLATION'
    ]),
    isRowDraggable(rowId) {
      return this.slotInstallations.includes(rowId);
    },
    updateWidgets(row, enabled) {
      const selectedWidgets = this.slotInstallations;
      if (enabled) {
        selectedWidgets.push(row.id);
        this.APPEND_INSTALLATION(row);
      } else {
        const index = selectedWidgets.findIndex(w => w === row.id);
        selectedWidgets.splice(index, 1);
        this.REMOVE_INSTALLATION(row);
      }

      this.slotInstallations = selectedWidgets;
      this.resetWidgetPosition();
    },
    resetWidgetPosition() {
      let index = 0;
      this.rows = this.rows.map(row => {
        if (this.slotInstallations.includes(row.id)) {
          index += 1;
          return { ...row, position: index };
        } else {
          return { ...row, position: null };
        }
      });

      this.updateSelectedInstallations();
    },
    sort({ srcIndex, dstIndex }) {
      this.rows = arrayMoveIndex(this.rows, srcIndex, dstIndex);
      this.resetWidgetPosition();
    },
    updateSelectedInstallations() {
      this.rows.forEach(row => {
        if (this.slotInstallations.includes(row.id))
          this.UPDATE_INSTALLATION(row);
      });
    },
    setDefaultWidget() {
      if (this.defaultSlotInstallationIds.length > 0) {
        this.slotInstallations = this.defaultSlotInstallationIds;
        this.resetWidgetPosition();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.SlotTable__badge {
  margin-left: 4px;
}
</style>

<i18n locale="ko">
{
  "form": {
    "position": "설치 순서",
    "widget_style": "위젯",
    "widget_info": "위젯 정보"
  },
  "widget_style": "{0} 위젯",
  "widget_info": "위젯 ID: {0}<br>위젯 이름: {1}",
  "using_widget": "사용 중"
}
</i18n>
