<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppResourceTable
      :resources="campaignMessages"
      :columns="columns"
      :rows="rows"
      :no-data="$t('no_data')"
      :custom-buttons="[sortButton]"
      :enable-total-count="false"
      table-id="campaign_message"
      @paginate="paginate"
    >
      <template #cell="{ column, row }">
        <template v-if="column === 'actions'">
          <ReviewMessagesCampaignMessageManageCell
            :message="row"
            :campaign-type="campaignType"
            @deleted="fetchResource"
            @refresh="fetchResource"
          />
        </template>
      </template>
    </AppResourceTable>
  </AppAjaxContent>
</template>

<script>
import api from '@/lib/api';
import { nullResources } from '@/lib/resources';
import ResourceView from '@/mixins/ResourceView';
import ReviewCampaignDispatchDaysType from '@/enums/ReviewCampaignDispatchDaysType';
import BizMessageTemplateType from '@/enums/BizMessageTemplateType';
import BizMessageTemplateInspectionStatus from '@/enums/BizMessageTemplateInspectionStatus';
import BizMessageTemplateStatus from '@/enums/BizMessageTemplateStatus';

export default {
  name: 'ReviewMessagesCampaignMessagesTable',
  mixins: [ResourceView],
  props: {
    campaignId: { type: Number, required: true },
    campaignType: { type: Number, default: null },
    eventBus: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      campaignMessages: nullResources,
      isLoading: true,
      sortButton: {
        icon: 'icon-sort',
        controlType: 'toggle',
        label: this.$t('button.sort.dispatch_hour_asc'),
        value: false,
        disabled: true
      }
    };
  },
  computed: {
    columns() {
      return [
        {
          id: 'id',
          label: this.$t('column.id.label'),
          width: '64px'
        },
        {
          id: 'dispatch_days',
          label: this.$t('column.dispatch_days.label'),
          width: '216px'
        },
        {
          id: 'dispatch_hour',
          label: this.$t('column.dispatch_hour.label'),
          width: '160px'
        },
        {
          id: 'biz_message_template',
          label: this.$t('column.biz_message_template.label')
        },
        {
          id: 'biz_message_template_status',
          label: this.$t('column.biz_message_template_status.label'),
          type: 'badge',
          width: '160px'
        },
        {
          id: 'actions',
          label: this.$t('column.actions.label'),
          width: '120px'
        }
      ];
    },
    rows() {
      return this.campaignMessages.items.map(message => ({
        ...message,
        dispatch_days: this.dispatchDaysText(
          message.dispatch_days_type,
          message.dispatch_days
        ),
        dispatch_hour: this.dispatchHourText(message.dispatch_hour),
        biz_message_template: message.biz_message_template.name,
        biz_message_template_status: this.bizMessageTemplateStatus(message)
      }));
    }
  },
  mounted() {
    this.eventBus.$on('refresh', () => this.fetchResource());
  },
  methods: {
    bizMessageTemplateStatus(message) {
      const { biz_message_template, biz_message_template_type } = message;
      if (biz_message_template_type === BizMessageTemplateType.GROUP) {
        return {
          badgeStyle: 'grey-outline',
          label: this.$t('group_template.status')
        };
      } else {
        return biz_message_template.inspection_status ===
          BizMessageTemplateInspectionStatus.APPROVED
          ? {
              badgeStyle: BizMessageTemplateStatus.badgeStyle(
                biz_message_template.status
              ),
              label: BizMessageTemplateStatus.t(biz_message_template.status)
            }
          : {
              badgeStyle: BizMessageTemplateInspectionStatus.badgeStyle(
                biz_message_template.inspection_status
              ),
              label: BizMessageTemplateInspectionStatus.t(
                biz_message_template.inspection_status
              ),
              tooltip: biz_message_template.rejected_message
            };
      }
    },
    fetchResource(params) {
      this.isLoading = true;
      api
        .get(`/review/campaigns/${this.campaignId}/messages`, { params })
        .then(({ data }) => {
          this.campaignMessages = data.campaign_messages;
          this.$emit(
            'update-messages-count',
            data.campaign_messages.total_count
          );
        })
        .finally(() => (this.isLoading = false));
    },
    dispatchDaysText(dispatch_days_type, dispatch_days) {
      if (
        dispatch_days_type ===
          ReviewCampaignDispatchDaysType.GIVE_MILEAGE_EXPIRED &&
        dispatch_days === 0
      )
        return this.$t('dispatch_days.give_mileage_expired.on_the_day');
      else
        return this.$t(
          `dispatch_days.${ReviewCampaignDispatchDaysType.toKey(
            dispatch_days_type
          ).toLowerCase()}.default`,
          [dispatch_days]
        );
    },
    dispatchHourText(hour) {
      return hour < 12
        ? this.$t('morning', [hour === 0 ? 12 : hour])
        : this.$t('afternoon', [hour === 12 ? 12 : hour - 12]);
    }
  }
};
</script>

<i18n locale="ko">
{
  "column": {
    "id": {
      "label": "ID"
    },
    "dispatch_days": {
      "label": "발송 시점"
    },
    "dispatch_hour": {
      "label": "발송 시간"
    },
    "biz_message_template": {
      "label": "메시지 템플릿"
    },
    "biz_message_template_status": {
      "label": "메시지 상태"
    },
    "actions": {
      "label": "관리"
    }
  },
  "dispatch_days": {
    "order_completed": {
      "default": "주문 완료 {0}일 후"
    },
    "give_mileage_expired": {
      "default": "적립금 지급 만료일 {0}일 남음",
      "on_the_day": "적립금 지급 만료일 당일"
    }
  },
  "group_template": {
    "status": "기본 템플릿"
  },
  "button": {
    "sort": {
      "dispatch_hour_asc": "발송시점 순"
    }
  },
  "morning": "오전 {0}시",
  "afternoon": "오후 {0}시",
  "no_data": "등록된 발송 메시지가 없습니다."
}
</i18n>
