<template>
  <div class="TheFloater">
    <TheHelp v-if="isTheHelpVisible" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import BrandInsightStatus from '@/enums/BrandInsightStatus';
import TheHelp from '@/layouts/TheHelp';

export default {
  name: 'TheFloater',
  components: { TheHelp },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapGetters('menu', ['selectedMainMenuItemId']),
    isTheHelpVisible() {
      return (
        ['review', 'fit', 'target', 'display'].includes(
          this.selectedMainMenuItemId
        ) ||
        ('analytics' === this.selectedMainMenuItemId &&
          BrandInsightStatus.isMenuAccessible(this.currentBrand.insight_status))
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_z-indexes.scss';

.TheFloater {
  position: absolute;
  right: 32px;
  bottom: 16px;
  z-index: $z-index-help;
}
</style>
