<template>
  <div class="QaNewFeatureSettingsServer">
    <AppAjaxContent :is-loading="isLoading">
      <AppForm v-bind="formProps" />
    </AppAjaxContent>
  </div>
</template>

<script>
import api from '@/lib/api';
import FormView from '@/mixins/FormView';

export default {
  name: 'QaNewFeatureSettingsServer',
  mixins: [FormView],
  data() {
    return { isLoading: false, data: null };
  },
  computed: {
    formSections() {
      if (this.data === null) return [];

      const groups = Object.keys(this.data).map(key => ({
        id: key,
        label: `${this.$t(key)} (${key})`,
        type: 'checkbox',
        disabled: true
      }));

      return [{ groups }];
    }
  },
  created() {
    this.fetchNewFeatureSettings();
  },
  methods: {
    fetchNewFeatureSettings() {
      this.isLoading = true;
      api
        .get('/qa/new_feature_settings')
        .then(({ data }) => {
          this.data = data;
          this.orgFormObject = data;
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<i18n locale="ko">
{
  "enable_jpeg_processor_vips": "Vips Jpeg 프로세서 사용 여부",
  "enable_webp_review_image": "WebP 리뷰 이미지 사용 여부",
  "enable_webp_review_photo_image_blurred": "WebP 블러 처리된 리뷰 이미지 사용 여부",
  "enable_webp_channel_product_image": "WebP 애드 상품 이미지 사용 여부",
  "enable_easy_join_review_bonus_period": "당일설치 무료체험 기간 부여 여부",
  "enable_force_join_as_imweb_test_mall": "아임웹 가입 테스트몰로 전환 여부",
  "enable_godo_one_day_installation": "고도몰 당일설치 적용 여부",
  "enable_makeshop_one_day_installation": "메이크샵 당일설치 적용 여부",
  "enable_easy_join_for_idle_cafe24": "카페24 유휴 고객사 당일설치 적용 여부",
  "enable_easy_join_for_idle_shopby": "샵바이 유휴 고객사 당일설치 적용 여부",
  "enable_check_smartstore_access_list": "스마트스토어 권한 수락 자동 체크",
  "enable_latte_rails_img_lazy_loading": "기존 위젯 이미지 lazy loading 적용 여부",
  "enable_widget_install_request_v2": "위젯 설치 요청 v2",
  "enable_v2_init_js": "v2 init.js 적용 여부",
  "enable_embedded_facebook": "포토팝업 페이스북 포스트 적용 여부",
  "enable_dispatch_method_biz_message_only": "리뷰 작성 유도 메시지 알림톡 전환 적용 여부",
  "enable_review_campaign": "리뷰 메시지 발송 캠페인 사용 여부",
  "enable_brand_review_csv_import": "일반관리자 리뷰 일괄 등록 기능 사용 여부",
  "enable_vue_widget_install_v2": "vue 위젯 설치 v2 사용 여부",
  "enable_list_v3_installation": "리스트 v3 설치 가능 여부",
  "enable_page_url_pattern_v2": "URL 패턴 관리 v2 사용 여부",
  "enable_install_request_renewal": "리뉴얼 위젯 설치 요청",
  "enable_nth_step_review_campaign": "n차 리뷰 작성 적용 여부",
  "enable_smartstore_invalid_checker": "스마트스토어 권한 실패 알림 여부"
}
</i18n>
