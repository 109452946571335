import _ from 'lodash';
import api from '@/lib/api';
import WidgetStyle from '@/enums/WidgetStyle';

export default {
  props: {
    requiredWidgetStyles: {
      type: Array,
      default: () => []
    },
    deviceType: { type: Number, required: true }
  },
  data() {
    return {
      widgets: []
    };
  },
  created() {
    api
      .get('/install_request/renewal/widgets', {
        params: {
          page_type: this.pageType,
          slot_type: this.slotType,
          device_type: this.deviceType
        }
      })
      .then(({ data }) => {
        this.widgets = data.widgets;
        this.rows = this.installableSlotWidgets();
        this.setDefaultWidget();
      });
  },
  methods: {
    installableSlotWidgets() {
      const installableWidgets = _.cloneDeep(this.widgets);
      this.requiredWidgetStyles.forEach(widgetStyle => {
        const requiredWidget = this.widgets.find(
          w => w.widget_style === widgetStyle
        );

        if (!requiredWidget) {
          installableWidgets.push({
            widget_device_type: this.deviceType,
            widget_style: widgetStyle
          });
        }
      });

      return installableWidgets.map(w => ({
        ...w,
        id: w.id ? w.id : WidgetStyle.key(w.widget_style).toLowerCase(),
        widget_id: w.id,
        page_type: this.pageType,
        slot_type: this.slotType,
        widget_style_name: this.$t('widget_style', [
          WidgetStyle.t(w.widget_style)
        ]),
        widget_info: w.id ? this.$t('widget_info', [w.id, w.name]) : '-'
      }));
    },
    setDefaultWidget() {}
  }
};
