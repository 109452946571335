<template>
  <SlotModalForm
    class="InstallRequestRenewalSlotMainBottomDialog"
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    is-maximized-by-default
    :form-props="formProps"
    v-on="formEvents"
  >
    <template #head>
      <SlotInfoCard :image-url="cardImageUrl" :name="cardName" />
    </template>
    <template #group="{ id }">
      <template v-if="id === 'pc_main_bottom'">
        <SlotTable
          :device-type="WidgetDeviceType.PC"
          :slot-type="InstallSlotType.MAIN_BOTTOM"
          :page-type="pageType"
          :required-widget-styles="requiredWidgetStyles"
        />
      </template>
      <template v-else-if="id === 'mobile_main_bottom'">
        <SlotTable
          :device-type="WidgetDeviceType.MOBILE"
          :slot-type="InstallSlotType.MAIN_BOTTOM"
          :page-type="pageType"
          :required-widget-styles="requiredWidgetStyles"
        />
      </template>
    </template>
  </SlotModalForm>
</template>

<script>
import DialogFormView from '@/mixins/DialogFormView';
import SlotModalForm from './components/SlotModalForm';
import SlotInfoCard from './components/SlotInfoCard';
import WidgetPageType from '@/enums/WidgetPageType';
import InstallSlotType from '@/enums/InstallSlotType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import WidgetStyle from '@/enums/WidgetStyle';

export default {
  name: 'InstallRequestRenewalSlotMainBottomDialog',
  components: { SlotModalForm, SlotInfoCard },
  mixins: [DialogFormView],
  data() {
    return {
      cardImageUrl:
        'https://assets.cre.ma/m/admin/v2/install_request/main_bottom_slot@3x.png',
      pageType: WidgetPageType.MAIN,
      requiredWidgetStyles: [
        WidgetStyle.ROUND_THUMBNAIL_GALLERY,
        WidgetStyle.MAIN_GALLERY,
        WidgetStyle.SQUARE_GALLERY,
        WidgetStyle.HORIZONTAL_SCROLL,
        WidgetStyle.EXTERNAL,
        WidgetStyle.GALLERY
      ]
    };
  },
  computed: {
    WidgetDeviceType() {
      return WidgetDeviceType;
    },
    InstallSlotType() {
      return InstallSlotType;
    },
    cardName() {
      return this.$t('card_name');
    },
    formSections() {
      return [
        {
          id: 'select_widget',
          label: this.$t('select_widget'),
          groups: [
            {
              id: 'tip',
              label: null,
              type: 'tip_box',
              value: this.$t('select_widget_tip')
            },
            {
              id: 'pc_main_bottom',
              label: this.$t('app.pc')
            },
            {
              id: 'mobile_main_bottom',
              label: this.$t('app.mobile')
            }
          ]
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .AppForm__group:last-child {
    margin-top: 32px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "설치 위젯 선택",
  "sub_title": "메인 페이지",
  "card_name": "페이지 최하단",
  "select_widget": "위젯 선택",
  "select_widget_tip": "설치할 위젯을 선택해 주세요. 여러개 위젯을 선택한 경우 설치 순서도 확인해주세요."
}
</i18n>
