<template>
  <div class="InstallRequestRenewalDirectionDialogMain">
    <SlotRequestCard
      v-for="(slot, i) in slotTypes"
      :key="i"
      v-bind="slotRequestCardProps(slot)"
      class="InstallRequestRenewalDirectionDialogMain__slot"
      @click="openInstallRequestRenewalSlotDialog(slot)"
    />
    <Banner
      class="InstallRequestRenewalDirectionDialogMain__banner"
      url="https://sample.cre.ma/v2/review/recommendation/main/300"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import InstallSlotType from '@/enums/InstallSlotType';
import WidgetPageType from '@/enums/WidgetPageType';
import SlotRequestCard from './components/SlotRequestCard';
import Banner from './components/Banner';

export default {
  name: 'InstallRequestRenewalDirectionDialogMain',
  components: { SlotRequestCard, Banner },
  computed: {
    slotTypes() {
      return [InstallSlotType.MAIN_BOTTOM, InstallSlotType.MAIN_CUSTOM];
    }
  },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    isMainDefaultSlot(slot) {
      return slot === InstallSlotType.MAIN_BOTTOM;
    },
    slotRequestCardProps(slot) {
      const key = InstallSlotType.key(slot).toLowerCase();
      return {
        imageUrl: `https://assets.cre.ma/m/admin/v2/install_request/${key}_slot@3x.png`,
        title: this.$t(`${key}.title`),
        description: this.$t(`${key}.description`),
        isRequired: this.isMainDefaultSlot(slot),
        slotType: slot,
        pageType: WidgetPageType.MAIN
      };
    },
    openInstallRequestRenewalSlotDialog(slot) {
      if (this.isMainDefaultSlot(slot)) {
        this.openDialog('InstallRequestRenewalSlotMainBottomDialog');
      } else {
        this.openDialog('InstallRequestRenewalSlotMainCustomDialog');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.InstallRequestRenewalDirectionDialogMain {
  margin-top: 24px;
}

.InstallRequestRenewalDirectionDialogMain__slot {
  & + & {
    margin-top: 12px;
  }
}

.InstallRequestRenewalDirectionDialogMain__banner {
  margin-top: 32px;
}
</style>

<i18n locale="ko">
{
  "main_bottom": {
    "title": "페이지 최하단",
    "description": "메인 페이지 최하단에 고객의 리뷰를 효과적으로 노출해보세요."
  },
  "main_custom": {
    "title": "직접 입력",
    "description": "메인 페이지 원하는 위치에 고객의 리뷰를 효과적으로 노출해보세요."
  }
}
</i18n>
