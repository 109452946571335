<template>
  <div class="SlotRequestCard">
    <div class="SlotRequestCard__image-box">
      <AppImage class="SlotRequestCard__image" :src="imageUrl" />
    </div>
    <div class="SlotRequestCard__content">
      <div>
        <span class="SlotRequestCard__title" v-text="title" />
        <AppBadge
          class="SlotRequestCard__badge"
          :label="badgeLabel"
          :badge-style="badgeStyle"
        />
      </div>
      <div class="SlotRequestCard__description">
        {{ description }}
      </div>
      <div
        :class="[
          'SlotRequestCard__info-box',
          slotInstallationInfo ? null : 'SlotRequestCard__info-box--no-data'
        ]"
      >
        <span
          v-if="slotInstallationInfo"
          class="SlotRequestCard__info-box-title"
          v-text="$t('requested_widget')"
        /><span>{{ slotInstallationInfo || $t('no_data') }}</span>
      </div>
      <AppButton
        class="SlotRequestCard__button"
        button-style="blue-outline"
        :label="$t('widget_select')"
        :bubble-message="bubbleMessage"
        bubble-side="bottom-center"
        @click="$emit('click')"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import WidgetStyle from '@/enums/WidgetStyle';
import InstallSlotType from '@/enums/InstallSlotType';

export default {
  name: 'SlotRequestCard',
  props: {
    imageUrl: { type: String, required: true },
    title: { type: String, required: true },
    description: { type: String, required: true },
    isRequired: { type: Boolean, default: false },
    bubbleMessage: { type: String, default: '' },
    slotType: { type: Number, required: true },
    pageType: { type: Number, required: true }
  },
  computed: {
    ...mapState('installRequest', ['installations']),
    badgeLabel() {
      return this.isRequired ? this.$t('required') : this.$t('optional');
    },
    badgeStyle() {
      return this.isRequired ? 'lavender' : 'default';
    },
    installationSlotTypes() {
      if (this.slotType === InstallSlotType.PRODUCT_DETAIL_DEFAULT)
        return [
          InstallSlotType.PRODUCT_DETAIL_DEFAULT,
          InstallSlotType.PRODUCT_DETAIL_DEFAULT_ADDITIONAL
        ];
      else return [this.slotType];
    },
    pcSlotInstallations() {
      return this.installations.filter(
        i =>
          this.pageType === i.page_type &&
          this.installationSlotTypes.includes(i.slot_type) &&
          WidgetDeviceType.PC === i.widget_device_type
      );
    },
    mobileSlotInstallations() {
      return this.installations.filter(
        i =>
          this.pageType === i.page_type &&
          this.installationSlotTypes.includes(i.slot_type) &&
          WidgetDeviceType.MOBILE === i.widget_device_type
      );
    },
    slotInstallationInfo() {
      const info = [];
      const pcInstallationStyles = this.pcSlotInstallations
        .map(i => WidgetStyle.t(i.widget_style))
        .join(', ');
      const mobileInstallationStyles = this.mobileSlotInstallations
        .map(i => WidgetStyle.t(i.widget_style))
        .join(', ');
      if (pcInstallationStyles !== '')
        info.push(`(${this.$t('app.pc')}) ${pcInstallationStyles}`);
      if (mobileInstallationStyles !== '')
        info.push(`(${this.$t('app.mobile')}) ${mobileInstallationStyles}`);

      return info.join(' / ');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';
@import '@/scss/mixins/_texts.scss';

.SlotRequestCard {
  display: flex;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
  min-height: 220px;
  border-radius: 6px;
}

.SlotRequestCard__image-box {
  width: 364px;
  background-color: $color-background-card;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px 0 0 6px;
}

.SlotRequestCard__content {
  padding: 20px;
  flex-grow: 1;
  background-color: $color-background;
  border-radius: 0px 6px 6px 0px;
}

.SlotRequestCard__title {
  @include text-sub-title;
}

.SlotRequestCard__badge {
  vertical-align: text-bottom;
  margin-left: 4px;
}

.SlotRequestCard__description {
  @include text-content;
  margin-top: 4px;
}

.SlotRequestCard__info-box {
  @include text-content-dark;
  margin-top: 8px;
  width: 100%;
  padding: 12px 16px 12px 16px;
  border-radius: 3px;
  border: 1px solid $color-grey-15;
  background-color: $color-grey-05;

  &--no-data {
    @include text-content-light;
  }
}

.SlotRequestCard__info-box-title {
  font-weight: bold;
  margin-right: 8px;
}

.SlotRequestCard__button {
  margin-top: 48px;
}
</style>

<i18n locale="ko">
{
  "required": "필수",
  "optional": "선택",
  "widget_select": "위젯 선택",
  "no_data": "요청 내용이 없습니다.",
  "requested_widget": "요청 위젯"
}
</i18n>
