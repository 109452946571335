<template>
  <div class="ReviewMessagesNthStepReviewCampaignStatCards">
    <AppStatCard
      class="ReviewMessagesNthStepReviewCampaignStatCards-card"
      :title="$t('stats.first_review_message_rate')"
      :detail="$t('stats.empty')"
    />
  </div>
</template>

<script>
export default {
  name: 'ReviewMessagesNthStepReviewCampaignStatCards'
};
</script>

<style scoped>
.ReviewMessagesNthStepReviewCampaignStatCards-card {
  width: 260px;
  display: inline-block;

  & + & {
    margin-left: 8px;
    margin-top: 0;
  }
}
</style>

<i18n locale="ko">
{
  "stats": {
    "first_review_message_rate": "1차 리뷰 작성률",
    "empty": "효과 수집 중입니다."
  }
}
</i18n>
