<template>
  <AppModalDefault
    :title="title"
    :sub-title="subTitle"
    :width="width"
    :can-maximize="canMaximize"
    :is-maximized-by-default="isMaximizedByDefault"
    :default-left-min-width="defaultLeftMinWidth"
    :default-main-min-width="defaultMainMinWidth"
    :show-bubble-top="showBubbleTop"
    :show-bubble-mid="showBubbleMid"
    :hide-head-close-button="hideHeadCloseButton"
    @close="$emit('close')"
  >
    <template #body>
      <div class="SlotModalForm__body">
        <div class="SlotModalForm__body-left">
          <AppAjaxContent :is-loading="isLoading" :height="height">
            <AppSearchbar v-if="$scopedSlots.searchbar">
              <slot name="searchbar" />
            </AppSearchbar>
            <AppForm v-bind="formProps" v-on="$listeners">
              <slot />
              <template #head>
                <slot name="head" />
              </template>
              <template #group="groupProps">
                <slot name="group" v-bind="groupProps" />
              </template>
              <template
                v-if="$scopedSlots['group-label']"
                #group-label="labelProps"
              >
                <slot name="group-label" v-bind="labelProps" />
              </template>
              <template
                v-if="$scopedSlots['group-sub-item']"
                #group-sub-item="subItemProps"
              >
                <slot name="group-sub-item" v-bind="subItemProps" />
              </template>
              <template #group-foot="groupProps">
                <slot name="group-foot" v-bind="groupProps" />
              </template>
              <template #description="descriptionProps">
                <slot name="description" v-bind="descriptionProps" />
              </template>
            </AppForm>
          </AppAjaxContent>
        </div>
        <div class="SlotModalForm__body-right">
          <SlotPreview />
        </div>
      </div>
    </template>
    <template #foot>
      <AppButtonSubmit
        v-if="!formProps.disabled"
        type="button"
        :button-style="
          formProps.submitStyle === 'danger' ? 'red' : formProps.submitStyle
        "
        :submit-label="formProps.submitLabel"
        :submitting-label="formProps.submittingLabel"
        :is-submitting="formProps.isSubmitting"
        :disabled="
          isLoading ||
            submitDisabled ||
            (formProps.detectFormDataChange && !formProps.isFormDataChanged)
        "
        :disabled-tooltip="submitDisabledTooltip"
        :error-summary="formProps.errorSummary"
        @click="formProps.eventBus.$emit('submit')"
      />
      <AppButton
        v-if="!hideCloseButton"
        :label="formProps.closeLabel || $t('app.close')"
        @click="$emit('close')"
      />
    </template>
  </AppModalDefault>
</template>

<script>
import SlotPreview from './SlotPreview';
import AppModalFormProps from '../../../../../src/components/AppModalFormProps';

export default {
  name: 'SlotModalForm',
  components: {
    SlotPreview
  },
  mixins: [AppModalFormProps],
  props: {
    height: { type: String, default: undefined },
    defaultLeftMinWidth: {
      type: Number,
      default: 600
    },
    defaultMainMinWidth: {
      type: Number,
      default: 418
    },
    showBubbleTop: {
      type: Boolean,
      default: false
    },
    showBubbleMid: {
      type: Boolean,
      default: false
    },
    hideCloseButton: {
      type: Boolean,
      default: false
    },
    hideHeadCloseButton: {
      type: Boolean,
      default: false
    },
    submitDisabled: {
      type: Boolean,
      default: false
    },
    submitDisabledTooltip: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars/_colors.scss';

::v-deep {
  .AppModalDefault__body {
    padding: 0;
  }

  .AppModal__head {
    border-bottom: 1px solid $color-grey-25;
  }

  .AppModal__foot {
    border-top: 1px solid $color-grey-25;
  }

  .AppForm__section:first-child {
    padding: 32px 0 0;
  }

  .AppForm__section + .AppForm__section {
    border-top: none;
    padding-top: 32px;
  }

  .AppForm__group {
    margin-top: 8px;
  }

  .AppForm__group + .AppForm__group {
    margin-top: 8px;
  }

  .AppTipBox {
    margin-bottom: 0 !important;
  }
}

.SlotModalForm__body {
  $header-height: 64px;
  $footer-height: 64px;

  display: flex;
  height: calc(100vh - ($header-height + $footer-height));
}

.SlotModalForm__body-left {
  width: 641px;
  padding: 16px 30px 0 30px;
  border-right: 1px solid $color-grey-25;
}

.SlotModalForm__body-right {
  flex-grow: 1;
}
</style>
