<template>
  <SlotModalForm
    class="InstallRequestRenewalSlotMainCustomDialog"
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    is-maximized-by-default
    :form-props="formProps"
    v-on="formEvents"
  >
    <template #head>
      <SlotInfoCard :image-url="cardImageUrl" :name="cardName" />
    </template>
    <template #group="{ id }">
      <template v-if="id === 'pc_main_custom'">
        <SlotTable
          :device-type="WidgetDeviceType.PC"
          :slot-type="InstallSlotType.MAIN_BOTTOM"
          :page-type="pageType"
          :required-widget-styles="requiredWidgetStyles"
        />
      </template>
      <template v-else-if="id === 'mobile_main_custom'">
        <SlotTable
          :device-type="WidgetDeviceType.MOBILE"
          :slot-type="InstallSlotType.MAIN_BOTTOM"
          :page-type="pageType"
          :required-widget-styles="requiredWidgetStyles"
        />
      </template>
      <template
        v-else-if="
          id === 'pc_custom_slot_comment' || id === 'mobile_custom_slot_comment'
        "
      >
        <AppTextInput :placeholder="$t('custom_slot.placeholder.comment')" />
        <AppInlineButton
          button-style="caption"
          :label="$t('attachment_guide')"
          @click="openAlertDialog"
        />
      </template>
    </template>
  </SlotModalForm>
</template>

<script>
import { mapActions } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import SlotModalForm from './components/SlotModalForm';
import SlotInfoCard from './components/SlotInfoCard';
import DialogSize from '@/enums/DialogSize';
import WidgetPageType from '@/enums/WidgetPageType';
import InstallSlotType from '@/enums/InstallSlotType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import WidgetStyle from '@/enums/WidgetStyle';

export default {
  name: 'InstallRequestRenewalSlotMainCustomDialog',
  components: { SlotModalForm, SlotInfoCard },
  mixins: [DialogFormView],
  data() {
    return {
      cardImageUrl:
        'https://assets.cre.ma/m/admin/v2/install_request/main_custom_slot@3x.png',
      pageType: WidgetPageType.MAIN,
      requiredWidgetStyles: [
        WidgetStyle.ROUND_THUMBNAIL_GALLERY,
        WidgetStyle.MAIN_GALLERY,
        WidgetStyle.SQUARE_GALLERY,
        WidgetStyle.HORIZONTAL_SCROLL,
        WidgetStyle.EXTERNAL,
        WidgetStyle.GALLERY
      ]
    };
  },
  computed: {
    WidgetDeviceType() {
      return WidgetDeviceType;
    },
    InstallSlotType() {
      return InstallSlotType;
    },
    cardName() {
      return this.$t('card_name');
    },
    formSections() {
      return [
        {
          id: 'pc_custom_slot',
          groups: [
            {
              id: 'pc_custom_slot_tip',
              label: this.$t('custom_slot.pc'),
              type: 'tip_box',
              value: this.$t('custom_slot.tip')
            },
            {
              id: 'pc_custom_slot_url',
              label: null,
              type: 'image',
              placeholder: this.$t('custom_slot.placeholder.pc_image_url'),
              validate: ['required']
            },
            {
              id: 'pc_custom_slot_comment',
              label: null,
              validate: ['required']
            }
          ]
        },
        {
          id: 'mobile_custom_slot',
          groups: [
            {
              id: 'mobile_custom_slot_tip',
              label: this.$t('custom_slot.mobile'),
              type: 'tip_box',
              value: this.$t('custom_slot.tip')
            },
            {
              id: 'mobile_custom_slot_url',
              label: null,
              type: 'image',
              placeholder: this.$t('custom_slot.placeholder.mobile_image_url'),
              validate: ['required']
            },
            {
              id: 'mobile_custom_slot_comment',
              label: null,
              validate: ['required']
            }
          ]
        },
        {
          id: 'select_widget',
          label: this.$t('select_widget'),
          groups: [
            {
              id: 'select_widget_tip',
              label: null,
              type: 'tip_box',
              value: this.$t('select_widget_tip')
            },
            {
              id: 'pc_main_custom',
              label: this.$t('app.pc')
            },
            {
              id: 'mobile_main_custom',
              label: this.$t('app.mobile')
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapActions('dialog', ['alert']),
    openAlertDialog() {
      this.alert({
        title: this.$t('attachment_guide'),
        message: this.$t('alert_message'),
        width: DialogSize.MIDDLE,
        closeButtonStyle: 'default',
        closeButtonLabel: this.$t('app.close')
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  #select_widget--mobile_main_custom {
    margin-top: 32px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "설치 위젯 선택",
  "sub_title": "메인 페이지",
  "card_name": "페이지 최하단",
  "select_widget": "위젯 선택",
  "select_widget_tip": "설치할 위젯을 선택해 주세요. 여러개 위젯을 선택한 경우 설치 순서도 확인해주세요.",
  "attachment_guide": "이미지 첨부 가이드",
  "alert_message": "아래 이미지와 같이 위젯을 설치하고 싶은 위치가 표시된 이미지를 첨부해주세요.<br><br>![](https://assets.cre.ma/m/admin/v2/install_request/attachment_guide_sample@3x.png)",
  "custom_slot" : {
    "pc": "PC 설치 위치",
    "mobile": "MOBILE 설치 위치",
    "tip": "설치와 무관한 내용은 반영되지 않습니다. 설치가 어려운 위치는 설치가 진행되지 않을 수 있습니다.",
    "placeholder": {
      "pc_image_url": "PC 설치 위치 이미지 첨부",
      "mobile_image_url": "MOBILE 설치 위치 이미지 첨부",
      "comment": "크리마 팀이 참고할 코멘트가 있다면 남겨주세요."
    }
  }
}
</i18n>
