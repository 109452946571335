<template>
  <div class="TheReviewSettingsTutorial">
    <div class="TheReviewSettingsTutorial__head">
      <span class="TheReviewSettingsTutorial__head-title">{{
        $t('title')
      }}</span>
    </div>
    <div class="TheReviewSettingsTutorial__body">
      <div class="TheReviewSettingsProgress__confirm-wrapper">
        <AppImage
          class="TheReviewSettingsProgress__confirm-star"
          src="https://assets.cre.ma/m/admin/v2/star_light.png"
        />
        <span class="TheReviewSettingsProgress__confirm-text">{{
          $t('confirm_text')
        }}</span>
      </div>
      <TheReviewSettingsProgress :total-count="4" :progress-count="1" />
      <div class="TheReviewSettingsTutorial__steps-wrapper">
        <TheReviewSettingsProgressStep
          v-for="(step, index) in steps"
          :key="index"
          :step="$t(step)"
        />
      </div>
    </div>
    <div class="TheReviewSettingsTutorial__footer">
      <div class="TheReviewSettingsProgress__foot">
        <i18n path="free_trial_days_left_content">
          <span class="TheReviewSettingsTutorial__days-left">{{
            $t('free_trial_days', [freeTrialDaysLeft])
          }}</span>
        </i18n>
      </div>
    </div>
  </div>
</template>

<script>
import TheReviewSettingsProgress from './TheReviewSettingsProgress';
import TheReviewSettingsProgressStep from './TheReviewSettingsProgressStep.vue';

export default {
  name: 'TheReviewSettingsTutorial',
  components: { TheReviewSettingsProgress, TheReviewSettingsProgressStep },
  data() {
    return {
      steps: [
        'biz_message',
        'review_settings_popup_text',
        'review_mileage',
        'review_import'
      ],
      freeTrialDaysLeft: 30
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins/_texts.scss';

.TheReviewSettingsTutorial {
  width: 250px;
  border-radius: 8px;
}

.TheReviewSettingsTutorial__head {
  padding: 23px 27px 12px 27px;
}

.TheReviewSettingsTutorial__head-title {
  @include text-label;
}

.TheReviewSettingsTutorial__body {
  @include text-caption-bold-dark;

  padding: 11px 23px;
}

.TheReviewSettingsTutorial__foot {
  position: relative;
  padding: 11px 23px 0 23px;
}

.TheReviewSettingsProgress__confirm-wrapper {
  display: flex;
}

.TheReviewSettingsProgress__confirm-star {
  width: 21px;
  height: 18px;
  margin-right: 6px;
}

.TheReviewSettingsTutorial__steps-wrapper {
  padding: 21px 0;
}

.TheReviewSettingsProgress__foot {
  @include text-content-tag;

  text-align: center;
  padding-bottom: 21px;
}

.TheReviewSettingsTutorial__days-left {
  color: $color-grey-75;
}
</style>

<i18n locale="ko">
{
  "title": "리뷰 기본 설정 가이드",
  "confirm_text": "주요 기본 설정을 확인해보세요",
  "biz_message": "알림톡 발송",
  "review_settings_popup_text": "리뷰 작성 팝업 문구",
  "review_mileage": "리뷰 작성 적립금 금액",
  "review_import": "쇼핑몰 외부 리뷰 연동",
  "free_trial_days_left_content": "무료 사용기간 {0} 남음",
  "free_trial_days": "{0}일"
}
</i18n>
