import _ from 'lodash';
import Vue from 'vue';

const state = {
  installRequestCurrentStep: 0,
  installDeviceTypes: [],
  bubbleVisibility: true,
  installations: []
};

const mutations = {
  SET_INSTALL_REQUEST_STEP(state, installRequestCurrentStep) {
    state.installRequestCurrentStep = installRequestCurrentStep;
  },
  SET_INSTALL_DEVICE_TYPES(state, deviceTypes) {
    state.installDeviceTypes = deviceTypes;
  },
  SET_BUBBLE_VISIBILITY(state, visibility) {
    state.bubbleVisibility = visibility;
  },
  APPEND_INSTALLATION(state, installation) {
    state.installations = [...state.installations, installation];
  },
  ADD_INSTALLATIONS(state, installations) {
    state.installations = [...state.installations, ...installations];
  },
  REMOVE_INSTALLATION(state, installation) {
    state.installations = _.reject(
      state.installations,
      i =>
        i.page_type === installation.page_type &&
        i.slot_type === installation.slot_type &&
        i.widget_device_type === installation.widget_device_type &&
        i.id === installation.id
    );
  },
  UPDATE_INSTALLATION(state, installation) {
    let index = state.installations.findIndex(
      i =>
        i.page_type === installation.page_type &&
        i.slot_type === installation.slot_type &&
        i.widget_device_type === installation.widget_device_type &&
        i.id === installation.id
    );
    if (index === -1) index = state.installations.length;
    Vue.set(state.installations, index, installation);
  },
  CLEAR_INSTALLATIONS(state) {
    state.installations = [];
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
