<template>
  <SlotModalForm
    class="InstallRequestRenewalSlotProductDetailDefaultDialog"
    :title="$t('title')"
    :sub-title="$t('sub_title')"
    is-maximized-by-default
    :form-props="formProps"
    v-on="formEvents"
  >
    <template #head>
      <SlotInfoCard :image-url="cardImageUrl" :name="cardName" />
    </template>
    <template #group="{ id }">
      <template v-if="id === 'pc_product_detail_default'">
        <SlotTableProductDetailDefault
          :device-type="WidgetDeviceType.PC"
          :required-widget-styles="requiredDefaultWidgetStyles"
        />
      </template>
      <template v-else-if="id === 'mobile_product_detail_default'">
        <SlotTableProductDetailDefault
          :device-type="WidgetDeviceType.MOBILE"
          :required-widget-styles="requiredDefaultWidgetStyles"
        />
      </template>
      <template v-else-if="id === 'pc_product_detail_default_additional'">
        <SlotTable
          :device-type="WidgetDeviceType.PC"
          :slot-type="InstallSlotType.PRODUCT_DETAIL_DEFAULT_ADDITIONAL"
          :page-type="pageType"
          :required-widget-styles="requiredAdditionalWidgetStyles"
        />
      </template>
      <template v-else-if="id === 'mobile_product_detail_default_additional'">
        <SlotTable
          :device-type="WidgetDeviceType.MOBILE"
          :slot-type="InstallSlotType.PRODUCT_DETAIL_DEFAULT_ADDITIONAL"
          :page-type="pageType"
          :required-widget-styles="requiredAdditionalWidgetStyles"
        />
      </template>
    </template>
  </SlotModalForm>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';
import ShopBuilder from '@/enums/ShopBuilder';
import WidgetPageType from '@/enums/WidgetPageType';
import InstallSlotType from '@/enums/InstallSlotType';
import WidgetDeviceType from '@/enums/WidgetDeviceType';
import WidgetStyle from '@/enums/WidgetStyle';
import DialogFormView from '@/mixins/DialogFormView';
import SlotModalForm from './components/SlotModalForm';
import SlotInfoCard from './components/SlotInfoCard';
import SlotTable from './components/SlotTable';
import SlotTableProductDetailDefault from './components/SlotTableProductDetailDefault';

export default {
  name: 'InstallRequestRenewalSlotProductDetailDefaultDialog',
  components: {
    SlotModalForm,
    SlotInfoCard,
    SlotTable,
    SlotTableProductDetailDefault
  },
  mixins: [DialogFormView],
  data() {
    return {
      cardImageUrl:
        'https://assets.cre.ma/m/admin/v2/install_request/product_detail_default_slot.png',
      pageType: WidgetPageType.PRODUCT_DETAIL,
      slotTypes: [
        InstallSlotType.PRODUCT_DETAIL_DEFAULT,
        InstallSlotType.PRODUCT_DETAIL_DEFAULT_ADDITIONAL
      ],
      requiredDefaultWidgetStyles: [WidgetStyle.LIST, WidgetStyle.BOARD],
      requiredAdditionalWidgetStyles: [
        WidgetStyle.GALLERY,
        WidgetStyle.EXTERNAL,
        WidgetStyle.HORIZONTAL_SCROLL
      ],
      oldInstallations: []
    };
  },
  computed: {
    ...mapState('session', ['currentBrand']),
    ...mapState('installRequest', ['installations', 'installDeviceTypes']),
    WidgetDeviceType() {
      return WidgetDeviceType;
    },
    InstallSlotType() {
      return InstallSlotType;
    },
    cardName() {
      return this.$t('card_name', [
        ShopBuilder.tk(this.currentBrand.shop_builder)
      ]);
    },
    formSections() {
      return [
        {
          id: 'default_slot',
          label: this.$t('default_slot'),
          groups: [
            {
              id: 'tip',
              label: null,
              type: 'tip_box',
              value: this.$t('default_slot_tip')
            },
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.PC)
              ? [
                  {
                    id: 'pc_product_detail_default',
                    label: this.$t('pc'),
                    validate: [{ rule: 'required', value: () => true }]
                  }
                ]
              : []),
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.MOBILE)
              ? [
                  {
                    id: 'mobile_product_detail_default',
                    label: this.$t('mobile'),
                    validate: [{ rule: 'required', value: () => true }]
                  }
                ]
              : [])
          ]
        },
        {
          id: 'additional_slot',
          label: this.$t('additional_slot'),
          groups: [
            {
              id: 'tip',
              label: null,
              type: 'tip_box',
              value: this.$t('additional_slot_tip')
            },
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.PC)
              ? [
                  {
                    id: 'pc_product_detail_default_additional',
                    label: this.$t('pc')
                  }
                ]
              : []),
            ...(this.installDeviceTypes?.includes(WidgetDeviceType.MOBILE)
              ? [
                  {
                    id: 'mobile_product_detail_default_additional',
                    label: this.$t('mobile')
                  }
                ]
              : [])
          ]
        }
      ];
    },
    productDetailInstallations() {
      return this.installations.filter(i => this.pageType === i.page_type);
    },
    isFormDataChanged() {
      const orgSortedInstallations = _.sortBy(this.oldInstallations, v => [
        v.id,
        v.slot_type,
        v.widget_device_type
      ]);
      const sortedInstallations = _.sortBy(
        this.productDetailInstallations,
        v => [v.id, v.slot_type, v.widget_device_type]
      );
      const result = !_.isEqual(orgSortedInstallations, sortedInstallations);
      return result;
    }
  },
  created() {
    this.oldInstallations = this.productDetailInstallations || [];
  },
  methods: {
    ...mapMutations('installRequest', [
      'ADD_INSTALLATIONS',
      'REMOVE_INSTALLATION'
    ]),
    beforeCloseDialog() {
      this.productDetailInstallations.forEach(installation =>
        this.REMOVE_INSTALLATION(installation)
      );
      this.ADD_INSTALLATIONS(this.oldInstallations);
    },
    submit() {
      this.close(true);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .AppForm__group:last-child {
    margin-top: 32px;
  }
}
</style>

<i18n locale="ko">
{
  "title": "설치 위젯 선택",
  "sub_title": "상품 상세 페이지",
  "card_name": "{0} 기본 리뷰 영역",
  "default_slot": "기본 위젯 선택",
  "additional_slot": "기본 위젯 상단에 설치할 위젯 선택",
  "default_slot_tip": "기존 리뷰 영역을 대체할 위젯을 선택해주세요.",
  "additional_slot_tip": "기본 위젯 상단에 위젯을 추가하고 싶을 경우 선택해주세요. 여러개 위젯을 선택한 경우 설치 순서도 확인해 주세요.",
  "pc": "PC",
  "mobile": "모바일"
}
</i18n>
