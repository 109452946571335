<template>
  <div class="InstallRequestRenewalDirectionDialogCountAndScore">
    InstallRequestRenewalDirectionDialogCountAndScore
  </div>
</template>

<script>
export default {
  name: 'InstallRequestRenewalDirectionDialogCountAndScore'
};
</script>
