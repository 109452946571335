<template>
  <AppAjaxContent :is-loading="isLoading">
    <AppModalFormLivePreview
      class="ChatbotMessageLastMessageFormDialog"
      :title="$t('title')"
      :sub-title="$t('sub_title')"
      :form-props="formProps"
      width="560px"
      @change-group="handleChange"
      v-on="formEvents"
    >
      <template #left>
        <BizMessageTemplatePreview
          :template="template"
          :show-message-head="false"
        />
      </template>
    </AppModalFormLivePreview>
  </AppAjaxContent>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import DialogFormView from '@/mixins/DialogFormView';
import BizMessageTemplatePreview from '../../biz_messages/dialogs/BizMessageTemplatePreview.vue';
import BizMessageTemplateEmphasizeType from '@/enums/BizMessageTemplateEmphasizeType';
import api from '@/lib/api';

export default {
  name: 'ChatbotMessageLastMessageFormDialog',
  components: { BizMessageTemplatePreview },
  mixins: [DialogFormView],
  data() {
    return {
      isLoading: true,
      imageEventBus: new Vue(),
      removeImage: false,
      ACCEPT_IMAGE_FILE_TYPES: ['image/jpg', 'image/jpeg', 'image/png']
    };
  },
  computed: {
    ...mapState('chatbotMessage', ['lastChatText', 'lastChatTextDefault']),
    formSections() {
      return [
        {
          id: 'reset',
          groups: [
            {
              id: 'use_default',
              label: this.$t('last_message.use_default'),
              type: 'button',
              value: this.$t('last_message.reset_to_default'),
              clickHandler: this.resetToDefault
            }
          ]
        },
        {
          id: 'message',
          groups: [
            {
              id: 'last_chat_image',
              label: this.$t('last_message.chat_image'),
              type: 'image',
              accept: this.ACCEPT_IMAGE_FILE_TYPES.join(','),
              placeholder: this.$t('last_message.chat_image_placeholder'),
              eventBus: this.imageEventBus,
              noPreview: true
            },
            {
              id: 'last_chat_text',
              label: this.$t('last_message.chat_text'),
              type: 'textarea',
              placeholder: this.$t('last_message.chat_text_placeholder'),
              autosize: true,
              maxlength: 230,
              validate: ['required', { rule: 'max_length', limit: 230 }]
            },
            {
              id: 'description',
              label: null,
              type: 'static',
              value: this.$t('last_message.description')
            }
          ]
        }
      ];
    },
    template() {
      return {
        emphasize_type: this.formObject.last_chat_image
          ? BizMessageTemplateEmphasizeType.IMAGE
          : BizMessageTemplateEmphasizeType.NONE,
        image: this.formObject.last_chat_image,
        content: this.formObject.last_chat_text,
        buttons: [
          { name: this.$t('buttons.review_for_other_products') },
          { name: this.$t('buttons.show_reviews') }
        ]
      };
    }
  },
  mounted() {
    this.isLoading = true;
    api
      .get('/review/chatbot_settings')
      .then(({ data }) => {
        const { last_chat_image_url } = data.chatbot_settings;
        this.orgFormObject = {
          ...this.orgFormObject,
          last_chat_text: this.lastChatText,
          last_chat_image: last_chat_image_url
        };
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    ...mapActions('chatbotMessage', ['updateMessageSettings']),
    resetToDefault() {
      this.formObject = {
        ...this.formObject,
        last_chat_text: this.lastChatTextDefault,
        last_chat_image: null
      };
      this.imageEventBus.$emit('reset');
    },
    submit(formData) {
      if (this.removeImage) formData.set('remove_last_chat_image', '1');
      if (formData.get('last_chat_image').size <= 0)
        formData.delete('last_chat_image');

      this.updateMessageSettings({ formData }).finally(() => this.close(true));
    },
    handleChange(e) {
      if (e.id === 'last_chat_image') {
        this.removeImage = !e.value.imageUrl || e.value.removeImage;
      }
    }
  }
};
</script>

<i18n locale="ko">
{
  "title": "마지막 메시지 설정",
  "sub_title": "챗봇",
  "last_message": {
    "use_default": "기본 메시지 사용",
    "reset_to_default": "초기 상태로 되돌리기",
    "chat_image": "이미지 첨부",
    "chat_image_placeholder": "이미지를 등록해주세요. (800×400px /JPG, PNG / 최대 500KB)",
    "chat_text": "템플릿 내용",
    "chat_text_placeholder": "한/영 구분없이 230자까지 입력 가능합니다.",
    "description": "버튼영역은 수정이 불가합니다. 제공되는 버튼은 왼쪽 화면에서 확인해주세요."
  },
  "buttons": {
    "review_for_other_products": "다른 상품에도 리뷰 남기기",
    "show_reviews": "작성한 리뷰 보러가기"
  }
}
</i18n>
