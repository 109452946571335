<template>
  <div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label>리뷰 기본 설정 가이드</label>
      </div>
      <TheReviewSettingsTutorial class="UiTutorialComponents__layouts" />
    </div>
    <div class="ui-section">
      <div class="ui-section__title">
        <label>TheWelcomeTutorialDialog</label>
      </div>
      <AppButton label="TheWelcomeTutorial" @click="openTheWelcomeTutorial" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TheReviewSettingsTutorial from '@/layouts/components/TheTutorial/TheReviewSettingsTutorial';

export default {
  name: 'UiTutorialComponents',
  components: { TheReviewSettingsTutorial },
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openTheWelcomeTutorial() {
      this.openDialog('TheWelcomeTutorialDialog');
    }
  }
};
</script>

<style scoped>
.UiTutorialComponents__layouts {
  border: solid;
}
</style>
