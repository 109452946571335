<template>
  <div class="InstallRequestRenewalDirectionDialogEtc">
    InstallRequestRenewalDirectionDialogEtc
  </div>
</template>

<script>
export default {
  name: 'InstallRequestRenewalDirectionDialogEtc'
};
</script>
