import { render, staticRenderFns } from "./ReviewMessagesCampaignMessagesTable.vue?vue&type=template&id=1952f154&"
import script from "./ReviewMessagesCampaignMessagesTable.vue?vue&type=script&lang=js&"
export * from "./ReviewMessagesCampaignMessagesTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./ReviewMessagesCampaignMessagesTable.vue?vue&type=custom&index=0&blockType=i18n&locale=ko"
if (typeof block0 === 'function') block0(component)

export default component.exports