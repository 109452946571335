const isNotProductionEnvironment = ({ state }) => state.env !== 'production';
const isRenewedVueWidgetInstall = ({ state }) =>
  state.session.adminSettings.enable_vue_widget_install_v2;

const isLegacyVueWidgetInstall = ({ state }) =>
  !state.session.adminSettings.enable_vue_widget_install_v2;

export default [
  {
    id: 'review_settings_super_admin_manage',
    menuItemId: 'review_settings_super_admin_manage',
    superAdmin: true,
    sections: [
      {
        id: 'manage',
        groups: [
          { id: 'review_launched_at' },
          { id: 'use_strong_security_policy' },
          { id: 'admin_usernames' },
          { id: 'push_review_to_shop_builder' },
          { id: 'push_reviews_count_to_shop_builder' },
          { id: 'skip_product_image_download' },
          { id: 'search_engine_noindex' },
          { id: 'file_attach_not_supported_powerapps_android_versions' },
          { id: 'detail_popup_only_ios_versions' },
          { id: 'disable_replace_state_in_mobile_app' },
          { id: 'require_photo' },
          { id: 'enable_review_allowed_author_type_delivery_finished' },
          { id: 'hide_review_messages_menu' },
          { id: 'hide_review_csv_button' },
          { id: 'pushable_reviews_space_size' },
          { id: 'use_toggle_byapps_bottom_menu' },
          { id: 'matching_review_with_order_code_in_my_orders' },
          { id: 'update_godo_product_reviews_count' },
          { id: 'allow_only_basic_chars_for_new_review' },
          { id: 'allow_category_ids_without_review_contents' },
          { id: 'use_video_reviews' },
          { id: 'use_smartstore_sync_menu' },
          { id: 'show_recommendation_products' }
        ]
      },
      {
        id: 'renewed',
        groups: [
          { id: 'use_inducing_popup_for_pc' },
          { id: 'use_product_category_product_type' }
        ]
      },
      {
        id: 'nlp_review_analysis',
        groups: [
          { id: 'nlp_category' },
          { id: 'use_nlp_by_openai' },
          { id: 'nlp_analyzable_reviews_limit' },
          {
            id: 'analyze_reviews_now',
            isAccessible: isNotProductionEnvironment
          }
        ]
      },
      {
        id: 'mileage',
        groups: [
          { id: 'mileage_message_encode_euc_kr' },
          { id: 'auto_mileage_batch' },
          { id: 'auto_mileage_batch_starts_after_days' },
          { id: 'auto_mileage_batch_interval_type' },
          { id: 'auto_mileage_message' },
          { id: 'enable_mileage_confirmation' },
          { id: 'include_order_code_on_mileage_action' },
          {
            id: 'skip_mileage',
            isAccessible: isNotProductionEnvironment
          }
        ]
      },
      {
        id: 'resend_messages',
        groups: [{ id: 'resend_date_range' }]
      },
      {
        id: 'import_and_translate',
        groups: [
          { id: 'source_brand_id' },
          {
            id: 'review_sync_transaction_id'
          },
          {
            id: 'review_sync_start_date'
          },
          { id: 'sync_comments_with_reviews' },
          { id: 'translate_matched_products' },
          { id: 'source_brand_review_sync_with_hide' },
          { id: 'option_translation_map' }
        ]
      }
    ]
  },
  {
    id: 'review_settings_super_admin_sms',
    menuItemId: 'review_settings_super_admin_sms',
    superAdmin: true,
    sections: [
      {
        id: 'default',
        groups: [{ id: 'sms_redirect_url' }]
      },
      {
        id: 'sms_unit_price',
        groups: [{ id: 'sms_unit_price' }, { id: 'nonmember_sms_unit_price' }]
      },
      {
        id: 'sms_text',
        groups: [
          { id: 'sms_message' },
          { id: 'nonmember_sms_message' },
          { id: 'offline_sms_message' }
        ]
      }
    ]
  },
  {
    id: 'review_settings_super_admin_biz_message',
    menuItemId: 'review_settings_super_admin_biz_message',
    superAdmin: true,
    sections: [
      {
        id: 'default',
        groups: [{ id: 'biz_message_unit_price' }, { id: 'biz_message_test' }]
      },
      {
        id: 'member',
        groups: [
          { id: 'sweettracker_biz_message_template_code_member' },
          { id: 'biz_message_text_member' },
          { id: 'biz_message_button_text_member' },
          { id: 'biz_message_button_url_member' }
        ]
      },
      {
        id: 'nonmember',
        groups: [
          { id: 'sweettracker_biz_message_template_code_nonmember' },
          { id: 'biz_message_text_nonmember' },
          { id: 'biz_message_button_text_nonmember' },
          { id: 'biz_message_button_url_nonmember' }
        ]
      },
      {
        id: 'offline',
        groups: [
          { id: 'sweettracker_biz_message_template_code_offline' },
          { id: 'biz_message_text_offline' },
          { id: 'biz_message_button_text_offline' },
          { id: 'biz_message_button_url_offline' }
        ]
      }
    ]
  },
  {
    id: 'review_settings_super_admin_service',
    menuItemId: 'review_settings_super_admin_service',
    superAdmin: true,
    sections: [
      {
        groups: [
          { id: 'confirm_godo_api_registration' },
          { id: 'download_unreviewed_sub_orders' },
          { id: 'download_video_reviews' },
          { id: 'download_using_after_use_review_badge_brands' },
          { id: 'download_using_review_sync_brands' },
          { id: 'download_user_statuses' }
        ]
      }
    ]
  },
  {
    id: 'review_settings_super_admin_vue_widget',
    menuItemId: 'review_settings_super_admin_vue_widget',
    superAdmin: true,
    sections: [
      {
        groups: [
          {
            id: 'show_vue_widget_setting_list',
            isAccessible: isRenewedVueWidgetInstall
          },
          {
            id: 'show_vue_widget_setting_gallery',
            isAccessible: isRenewedVueWidgetInstall
          },
          {
            id: 'show_vue_widget_setting_main_gallery',
            isAccessible: isRenewedVueWidgetInstall
          },
          {
            id: 'show_vue_widget_setting_best_gallery',
            isAccessible: isRenewedVueWidgetInstall
          },
          {
            id: 'show_vue_widget_setting_square_gallery',
            isAccessible: isRenewedVueWidgetInstall
          },
          {
            id: 'show_vue_widget_setting_horizontal_scroll',
            isAccessible: isRenewedVueWidgetInstall
          },
          {
            id: 'show_vue_widget_setting_external',
            isAccessible: isRenewedVueWidgetInstall
          },
          {
            id: 'show_vue_widget_setting_photo_thumbnail',
            isAccessible: isRenewedVueWidgetInstall
          },
          {
            id: 'show_vue_widget_setting_board',
            isAccessible: isRenewedVueWidgetInstall
          },
          {
            id: 'enable_vue_widget_list',
            isAccessible: isLegacyVueWidgetInstall
          },
          {
            id: 'enable_vue_widget_gallery',
            isAccessible: isLegacyVueWidgetInstall
          },
          {
            id: 'enable_vue_widget_main_gallery',
            isAccessible: isLegacyVueWidgetInstall
          },
          {
            id: 'enable_vue_widget_best_gallery',
            isAccessible: isLegacyVueWidgetInstall
          },
          {
            id: 'enable_vue_widget_horizontal_scroll',
            isAccessible: isLegacyVueWidgetInstall
          },
          {
            id: 'enable_vue_widget_square_gallery',
            isAccessible: isLegacyVueWidgetInstall
          },
          {
            id: 'enable_vue_widget_external',
            isAccessible: isLegacyVueWidgetInstall
          },
          {
            id: 'enable_vue_widget_photo_thumbnail',
            isAccessible: isLegacyVueWidgetInstall
          },
          {
            id: 'enable_vue_widget_board',
            isAccessible: isLegacyVueWidgetInstall
          },
          {
            id: 'enable_vue_widget_product_options',
            isAccessible: isLegacyVueWidgetInstall
          },
          {
            id: 'enable_vue_widget_keyword',
            isAccessible: isLegacyVueWidgetInstall
          },
          {
            id: 'enable_vue_widget_detachable_board',
            isAccessible: isLegacyVueWidgetInstall
          },
          {
            id: 'enable_vue_widget_products',
            isAccessible: isLegacyVueWidgetInstall
          },
          { id: 'enable_vue_widget_reviews_new' },
          { id: 'enable_vue_widget_count_and_score' },
          { id: 'enable_vue_widget_managing_reviews' },
          { id: 'enable_vue_widget_target_product_reviews' }
        ]
      }
    ]
  }
];
