<template>
  <AppTable
    class="SlotTableProductDetailDefault AppForm__group-field AppForm__group-field--mt8"
    :columns="columns"
    :rows="rows"
  >
    <template #cell="{ column, row }">
      <template v-if="column.id === 'widget_style'">
        <span>{{ row.widget_style_name }}</span>
        <AppBadge
          v-if="row.widget_id"
          class="SlotTableProductDetailDefault__badge"
          badge-style="mint-green-outline"
          badget-size="small"
          :label="$t('using_widget')"
        />
      </template>
      <template v-else-if="column.id === 'select'">
        <AppRadio
          v-model="installation"
          :name="`product_detail_default_installation_${deviceType}`"
          :value="row.id"
        />
      </template>
    </template>
  </AppTable>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';
import WidgetStyle from '@/enums/WidgetStyle';
import InstallSlotType from '@/enums/InstallSlotType';
import WidgetPageType from '@/enums/WidgetPageType';
import InstallRequestRenewalSlotTableView from '@/mixins/InstallRequestRenewalSlotTableView';

export default {
  name: 'SlotTableProductDetailDefault',
  mixins: [InstallRequestRenewalSlotTableView],
  data() {
    return {
      rows: [],
      installation: null,
      slotType: InstallSlotType.PRODUCT_DETAIL_DEFAULT,
      pageType: WidgetPageType.PRODUCT_DETAIL
    };
  },
  computed: {
    ...mapState('installRequest', ['installations']),
    columns() {
      return [
        {
          id: 'widget_style',
          label: this.$t('form.widget_style'),
          width: '30%'
        },
        {
          id: 'widget_info',
          type: 'html',
          label: this.$t('form.widget_info'),
          width: '40%'
        },
        {
          id: 'select',
          label: this.$t('app.select'),
          width: '30%'
        }
      ];
    },
    productDetailDefaultInstallation() {
      return this.installations.find(
        i =>
          this.pageType === i.page_type &&
          this.slotType === i.slot_type &&
          this.deviceType === i.widget_device_type
      );
    }
  },
  watch: {
    installation(newValue, oldValue) {
      if (!_.isNil(oldValue)) {
        this.REMOVE_INSTALLATION(this.getInstallation(oldValue));

        if (!_.isNil(newValue))
          this.APPEND_INSTALLATION(this.getInstallation(newValue));
      }
    }
  },
  methods: {
    ...mapMutations('installRequest', [
      'APPEND_INSTALLATION',
      'REMOVE_INSTALLATION'
    ]),
    setDefaultWidget() {
      if (this.productDetailDefaultInstallation)
        this.installation = this.productDetailDefaultInstallation.id;
      else {
        const defaultWidget = this.rows.find(
          row => row.widget_style === WidgetStyle.LIST
        );
        this.installation = defaultWidget.id;
        this.APPEND_INSTALLATION(defaultWidget);
      }
    },
    getInstallation(rowId) {
      return this.rows.find(row => row.id === rowId);
    }
  }
};
</script>

<style lang="scss" scoped>
.SlotTableProductDetailDefault__badge {
  margin-left: 4px;
}
</style>

<i18n locale="ko">
{
  "form": {
    "widget_style": "위젯",
    "widget_info": "위젯 정보"
  },
  "widget_style": "{0} 위젯",
  "widget_info": "위젯 ID: {0}<br>위젯 이름: {1}",
  "using_widget": "사용 중"
}
</i18n>
