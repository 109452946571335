import { constructEnum } from '@/lib/enum';

const InstallSlotType = constructEnum('InstallSlotType', {
  PRODUCT_DETAIL_DEFAULT: 10,
  PRODUCT_DETAIL_DEFAULT_ADDITIONAL: 11,
  PRODUCT_DETAIL_PRODUCT_THUMBNAIL_AFTER: 12,
  PRODUCT_DETAIL_DESC_SECTION_TOP: 13,
  MAIN_BOTTOM: 20,
  MAIN_CUSTOM: 21,
  REVIEW_BOARD: 30,
  EVENT: 40,
  CATEGORY: 50
});

export default InstallSlotType;
