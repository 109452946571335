<template>
  <AppModalFormLivePreview
    :title="title"
    :sub-title="$t('subtitle')"
    :form-props="{
      ...formProps,
      objectId: 'review_campaign_message'
    }"
    width="440px"
    :is-loading="isLoading"
    v-on="formEvents"
  >
    <input type="hidden" name="message[campaign_type]" :value="campaignType" />
    <input
      type="hidden"
      name="message[biz_message_template_type]"
      :value="formObject.biz_message_template_type"
    />
    <input
      type="hidden"
      name="message[biz_message_template_id]"
      :value="formObject.biz_message_template_id"
    />
    <template #left>
      <AppAjaxContent :is-loading="isLoadingPreview">
        <BizMessageTemplatePreview :template="template" />
      </AppAjaxContent>
    </template>
    <template #group="group">
      <Component
        :is="reviewMessageFormName"
        v-bind="{
          group,
          message,
          mileageExiryDays,
          nthStepReviewMileage
        }"
        @change-template="onChangeTemplate"
        @blur="validateField($event)"
        @change="validateField($event)"
      />
    </template>
  </AppModalFormLivePreview>
</template>

<script>
import api from '@/lib/api';
import { mapState, mapActions } from 'vuex';
import BizMessageTemplatePreview from '@/views/biz_messages/dialogs/BizMessageTemplatePreview';
import BizMessageTemplateShowView from '@/mixins/BizMessageTemplateShowView';
import DialogFormView from '@/mixins/DialogFormView';
import ReviewCampaignType from '@/enums/ReviewCampaignType';
import CremaServiceType from '@/enums/CremaServiceType';
import ReviewMessagesMemberReviewCampaignForm from './ReviewMessagesMemberReviewCampaignForm.vue';

export default {
  name: 'ReviewMessagesMessageFormDialog',
  components: {
    BizMessageTemplatePreview,
    ReviewMessagesMemberReviewCampaignForm
  },
  mixins: [DialogFormView, BizMessageTemplateShowView],
  props: {
    campaignType: { type: Number, required: true },
    campaignMessageId: { type: Number, default: null }
  },
  data: () => ({
    isLoadingMessage: true,
    isLoadingTemplates: true,
    isLoadingPreview: false,
    giveMileageExpiresAfterDays: 0,
    nthStepReviewMileageExipryDays: 0,
    nthStepReviewMileage: 0
  }),
  computed: {
    ...mapState('bizMessageTemplate', ['templateType']),
    message() {
      return this.formObject;
    },
    isLoading() {
      return this.isLoadingMessage || this.isLoadingTemplates;
    },
    title() {
      return ReviewCampaignType.t(this.campaignType);
    },
    formSections() {
      return [
        {
          groups: [
            {
              id: 'dispatch_recipent',
              label: this.$t('dispatch_recipent'),
              fields: [
                {
                  id: 'dispatch_days',
                  validate: ['required', { rule: 'lte', allowed: 365 }]
                }
              ]
            },
            {
              id: 'dispatch_hour',
              label: this.$t('dispatch_hour')
            },
            ...(this.campaignType === ReviewCampaignType.NTH_STEP_REVIEW
              ? [
                  {
                    id: 'mileage',
                    label: this.$t('mileage')
                  }
                ]
              : []),
            {
              id: 'biz_message_template_id',
              label: this.$t('biz_message_template_id'),
              validate: ['required']
            }
          ]
        }
      ];
    },
    campaignMessageIdExist() {
      return Boolean(this.campaignMessageId);
    },
    reviewMessageFormName() {
      switch (this.campaignType) {
        case ReviewCampaignType.MEMBER_REVIEW:
          return 'ReviewMessagesMemberReviewCampaignForm';
        case ReviewCampaignType.NTH_STEP_REVIEW:
          return 'ReviewMessagesNthReviewCampaignForm';
        default:
          return '';
      }
    },
    mileageExiryDays() {
      return this.campaignType === ReviewCampaignType.MEMBER_REVIEW
        ? this.giveMileageExpiresAfterDays
        : this.nthStepReviewMileageExipryDays;
    }
  },
  watch: {
    'formObject.biz_message_template_id'() {
      this.setTemplateData();
      this.validateField('biz_message_template_id');
    }
  },
  mounted() {
    this.fetchTemplates({
      serviceType: CremaServiceType.REVIEW
    }).finally(() => (this.isLoadingTemplates = false));

    api
      .get(
        this.campaignMessageIdExist
          ? `/review/campaign/messages/${this.campaignMessageId}/edit`
          : '/review/campaign/messages/new'
      )
      .then(({ data }) => {
        this.orgFormObject = { ...data.message };
        this.giveMileageExpiresAfterDays = data.give_mileage_expires_after_days;
        this.nthStepReviewMileageExipryDays =
          data.nth_step_review_mileage_expiry_days;
        this.nthStepReviewMileage =
          data.nth_step_review_additional_mileage_cents;
      })
      .finally(() => (this.isLoadingMessage = false));
  },
  methods: {
    ...mapActions('bizMessageTemplate', ['fetchTemplates', 'fetchTemplate']),
    submit(formData) {
      this.isSubmitting = true;
      const request = this.campaignMessageIdExist
        ? api.patch(
            `/review/campaign/messages/${this.campaignMessageId}`,
            formData
          )
        : api.post('/review/campaign/messages', formData);

      request
        .then(() => {
          this.emit('submit');
          this.close(true);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    setTemplateData() {
      const {
        biz_message_template_id,
        biz_message_template_type
      } = this.formObject;
      if (this.formObject.biz_message_template_id) {
        this.isLoadingPreview = true;
        this.fetchTemplate({
          id: biz_message_template_id,
          templateType: biz_message_template_type
        })
          .then(
            ({ data }) => (this.template = this.formatTemplate(data.template))
          )
          .finally(() => (this.isLoadingPreview = false));
      } else this.template = { buttons: [] };
    },
    onChangeTemplate(template) {
      this.formObject = { ...this.formObject, ...template };
    }
  }
};
</script>

<i18n locale="ko">
{
  "subtitle": "발송 메시지 설정",
  "dispatch_recipent": "발송 대상",
  "dispatch_hour": "메시지 발송 시간",
  "biz_message_template_id": "발송할 메시지",
  "mileage": "적립금"
}
</i18n>
