<template>
  <div class="ReviewSettingsShowHeaderSectionSubSetting">
    <div v-if="subSettingName === 'use_title_and_description'">
      <AppCheckbox
        v-model="widget.use_title_and_description"
        name="widget[use_title_and_description]"
        :disabled="disabledShowTitleSubSetting"
        :label="$t('use_title_and_description_label')"
        @change="$emit('change')"
      />
      <div
        class="ReviewSettingsShowHeaderSectionSubSetting__sub-group ReviewSettingsShowHeaderSectionSubSetting__sub-item"
      >
        <label
          :class="[
            'AppForm__sub-group-title',
            {
              'AppForm__sub-group-title--disabled': disabledUseTitleAndDescriptionSubSetting
            }
          ]"
          for="widget[title]"
        >
          {{ $t('widget_title.label') }}
          <span
            class="ReviewSettingsShowHeaderSectionSubSetting__group-title-required"
          >
            *
          </span>
        </label>
        <AppTextInput
          v-model="widget.title"
          :placeholder="$t('widget_title.placeholder')"
          :disabled="disabledUseTitleAndDescriptionSubSetting"
          :invalid="!!group.errors.use_title_and_description"
          name="widget[title]"
          @blur="$emit('blur')"
        />
        <AppFormError :error="group.errors.use_title_and_description" />
      </div>
    </div>
    <div
      v-else-if="subSettingName === 'description_text'"
      :class="[
        'AppForm__sub-group-item',
        'ReviewSettingsShowHeaderSectionSubSetting__sub-item'
      ]"
    >
      <label
        :class="[
          'AppForm__sub-group-title',
          {
            'AppForm__sub-group-title--disabled': disabledUseTitleAndDescriptionSubSetting
          }
        ]"
        for="widget[description_text]"
      >
        {{ $t('description_text.label') }}
      </label>
      <AppTextInput
        v-model="widget.description_text"
        :placeholder="$t('description_text.placeholder')"
        :disabled="disabledUseTitleAndDescriptionSubSetting"
        :invalid="!!group.errors.description_text"
        name="widget[description_text]"
        @blur="$emit('blur')"
      />
      <AppFormHint
        :message="$t('description_text.description')"
        :disabled="disabledUseTitleAndDescriptionSubSetting"
      />
      <AppFormError :error="group.errors.description_text" />
    </div>
    <div
      v-else-if="subSettingName === 'reviews_button_title'"
      class="AppForm__sub-group-item"
    >
      <AppCheckbox
        v-model="widget.use_reviews_button"
        name="widget[use_reviews_button]"
        :disabled="disabledShowTitleSubSetting"
        :label="$t('use_reviews_button_label')"
        @change="$emit('change')"
      />
      <div
        class="ReviewSettingsShowHeaderSectionSubSetting__sub-group ReviewSettingsShowHeaderSectionSubSetting__sub-item"
      >
        <ReviewSettingsWidgetsNormalFormReviewsButtonTitle
          :widget="widget"
          :disabled="disabledShowTitleSubSetting || !widget.use_reviews_button"
          :error="group.errors.reviews_button_title"
          @title-blur="$emit('blur')"
        />
      </div>
    </div>
    <div
      v-else-if="subSettingName === 'show_notices'"
      class="AppForm__sub-group-item"
    >
      <AppCheckbox
        v-model="widget.show_notices"
        name="widget[show_notices]"
        :label="$t('show_notices_label')"
        :disabled="disabledShowTitleSubSetting"
        @change="$emit('change')"
      />
      <div
        class="ReviewSettingsShowHeaderSectionSubSetting__show-mileage-notice"
      >
        <AppCheckbox
          v-model="widget.show_mileage_notices"
          name="widget[show_mileage_notices]"
          :label="$t('show_mileage_notices.label')"
          :disabled="disabledShowTitleSubSetting || !widget.show_notices"
          @change="$emit('change')"
        />
        <AppFormGroupDescription
          :message="$t('show_mileage_notices.description')"
          :disabled="disabledShowTitleSubSetting || !widget.show_notices"
          mode="markdown"
          class="ReviewSettingsShowHeaderSectionSubSetting__show-mileage-notice-description"
        />
      </div>
    </div>
    <div
      v-else-if="subSettingName === 'review_write_button_label'"
      class="AppForm__sub-group-item"
    >
      <AppCheckbox
        v-model="widget.use_review_write_button"
        name="widget[use_review_write_button]"
        :label="$t('use_review_write_button_label')"
        :disabled="disabledShowTitleSubSetting"
        @change="$emit('change')"
      />
      <div
        class="ReviewSettingsShowHeaderSectionSubSetting__sub-group ReviewSettingsShowHeaderSectionSubSetting__sub-item"
      >
        <AppTextInput
          v-model="widget.review_write_button_label"
          :disabled="
            disabledShowTitleSubSetting || !widget.use_review_write_button
          "
          :invalid="!!group.errors.review_write_button_label"
          :placeholder="$t('review_write_button_label_placeholder')"
          name="widget[review_write_button_label]"
          @blur="$emit('blur')"
        />
        <AppFormError :error="group.errors.review_write_button_label" />
      </div>
    </div>
    <div v-else-if="subSettingName === 'show_product_score'">
      <AppCheckbox
        v-model="widget.show_product_score"
        name="widget[show_product_score]"
        :label="$t('show_product_score_label')"
        :disabled="disabledShowScoreSectionSubSetting"
        @change="$emit('change')"
      />
    </div>
    <div v-else-if="subSettingName === 'show_product_score_histogram'">
      <AppCheckbox
        v-model="widget.show_product_score_histogram"
        name="widget[show_product_score_histogram]"
        :label="$t('show_product_score_histogram_label')"
        :disabled="disabledShowScoreSectionSubSetting"
        @change="$emit('change')"
      />
    </div>
    <div v-else-if="subSettingName === 'show_evaluation_option_type_graph'">
      <AppCheckbox
        v-model="widget.show_evaluation_option_type_graph"
        name="widget[show_evaluation_option_type_graph]"
        :label="$t('show_evaluation_option_type_graph_label')"
        :disabled="disabledShowScoreSectionSubSetting"
        @change="$emit('change')"
      />
    </div>
    <div v-else-if="subSettingName === 'media_summary_style'">
      <label
        :class="[
          'AppForm__sub-group-title',
          {
            'AppForm__sub-group-title--disabled': disabledShowMediaSummary
          }
        ]"
        for="widget[media_summary_style]"
      >
        {{ $t('media_summary_style_label') }}
      </label>
      <AppSelectRadio
        v-model="widget.media_summary_style"
        name="widget[media_summary_style]"
        :disabled="disabledShowMediaSummary"
        :options="MediaSummaryStyle.options()"
        @change="$emit('change')"
      />
    </div>
    <div v-else-if="subSettingName === 'media_summary_sort'">
      <label
        :class="[
          'AppForm__sub-group-title',
          {
            'AppForm__sub-group-title--disabled': disabledShowMediaSummary
          }
        ]"
        for="widget[media_summary_sort]"
      >
        {{ $t('media_summary_sort_label') }}
      </label>
      <AppSelectRadio
        v-model="widget.media_summary_sort"
        name="widget[media_summary_sort]"
        :disabled="disabledShowMediaSummary"
        :options="
          reviewSortOptions([
            ReviewSort.RECENTLY_CREATED,
            ReviewSort.MOST_SCORES,
            ReviewSort.MOST_LIKES
          ])
        "
        @change="$emit('change')"
      />
    </div>
    <div v-if="subSettingName === 'show_product_option_filter'">
      <AppCheckbox
        v-model="widget.show_product_option_filter"
        :label="$t('show_product_option_filter_label')"
        name="widget[show_product_option_filter]"
        :disabled="disabledShowFilterSection"
        @change="$emit('change')"
      />
    </div>
    <div v-if="subSettingName === 'show_review_photo_first_button'">
      <AppCheckbox
        v-model="widget.show_review_photo_first_button"
        :label="$t('show_review_photo_first_button_label')"
        name="widget[show_review_photo_first_button]"
        :disabled="disabledShowFilterSection"
        @change="$emit('change')"
      />
    </div>
    <div v-if="subSettingName === 'show_size_filter'">
      <ReviewSettingsShowSizeFilter
        v-if="showSizeFilter"
        :widget="widget"
        :disabled="disabledShowFilterSection"
        @change="$emit('change')"
      />
    </div>
    <div v-if="subSettingName === 'show_score_filter'">
      <ReviewSettingsShowScoreFilter
        :widget="widget"
        :disabled="disabledShowFilterSection"
        :label="$t('show_score_filter_label')"
        @change="$emit('change')"
      />
    </div>
    <div v-if="subSettingName === 'use_review_keyword_filter'">
      <AppCheckbox
        v-model="widget.use_review_keyword_filter"
        :label="$t('use_review_keyword_filter_label')"
        name="widget[use_review_keyword_filter]"
        :disabled="disabledShowFilterSection"
        @change="$emit('change')"
      />
    </div>
    <div v-if="subSettingName === 'show_top_filter_menu'">
      <AppCheckbox
        v-model="widget.show_top_filter_menu"
        :label="$t('show_top_filter_menu_label')"
        name="widget[show_top_filter_menu]"
        :disabled="disabledShowFilterSection"
        @change="$emit('change')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ReviewSettingsShowScoreFilter from './ReviewSettingsShowScoreFilter';
import ReviewSettingsShowSizeFilter from './ReviewSettingsShowSizeFilter';
import ReviewSettingsWidgetsNormalFormReviewsButtonTitle from '@/views/review/settings/ReviewSettingsWidgetsNormalFormReviewsButtonTitle';
import MediaSummaryStyle from '@/enums/MediaSummaryStyle';
import ReviewSort from '@/enums/ReviewSort';

export default {
  name: 'ReviewSettingsShowHeaderSectionSubSetting',
  components: {
    ReviewSettingsShowScoreFilter,
    ReviewSettingsShowSizeFilter,
    ReviewSettingsWidgetsNormalFormReviewsButtonTitle
  },
  props: {
    subSettingName: {
      type: String,
      required: true
    },
    widget: {
      type: Object,
      required: true
    },
    group: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('session', ['currentBrand', 'reviewSettings']),
    ...mapGetters('reviewWidgets', ['reviewSortOptions']),
    MediaSummaryStyle: () => MediaSummaryStyle,
    ReviewSort: () => ReviewSort,
    disabledShowTitleSubSetting() {
      return !(this.widget.show_header_section && this.widget.show_title);
    },
    disabledShowScoreSectionSubSetting() {
      return !(
        this.widget.show_header_section && this.widget.show_score_section
      );
    },
    disabledUseTitleAndDescriptionSubSetting() {
      return (
        this.disabledShowTitleSubSetting ||
        !this.widget.use_title_and_description
      );
    },
    disabledShowMediaSummary() {
      return !(
        this.widget.show_header_section && this.widget.show_media_summary
      );
    },
    disabledShowFilterSection() {
      return !(
        this.widget.show_header_section && this.widget.show_filter_section
      );
    },
    showSizeFilter() {
      return (
        this.currentBrand.is_fashion_mall &&
        !this.reviewSettings.use_product_category_product_type
      );
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/vars/_colors.scss';

.ReviewSettingsShowHeaderSectionSubSetting__sub-item {
  margin-left: 22px;
}

.ReviewSettingsShowHeaderSectionSubSetting__sub-group {
  margin-top: 4px;
}

.ReviewSettingsShowHeaderSectionSubSetting__group-title-required {
  color: $color-red;
}

.ReviewSettingsShowHeaderSectionSubSetting__show-mileage-notice,
.ReviewSettingsShowHeaderSectionSubSetting__show-mileage-notice-description {
  margin: 4px 0 0 22px;
}
</style>

<i18n locale="ko">
{
  "use_title_and_description_label": "위젯 제목 및 설명",
  "use_reviews_button_label": "전체 리뷰 게시판 이동 버튼 사용",
  "show_notices_label": "공지사항 사용",
  "use_review_write_button_label": "리뷰 작성하기 버튼 사용",
  "review_write_button_label_placeholder": "리뷰 작성창으로 이동하는 버튼 문구를 입력해주세요.",
  "show_product_score_label": "평점 표시 사용",
  "show_product_score_histogram_label": "별점 분포 그래프 사용",
  "show_evaluation_option_type_graph_label": "추가 정보 평가 그래프 사용",
  "media_summary_style_label": "포토&동영상 모아보기 스타일",
  "media_summary_sort_label": "포토&동영상 모아보기 정렬 순서",
  "show_product_option_filter_label": "상품옵션 필터",
  "show_review_photo_first_button_label": "포토&동영상 먼저 보기 버튼",
  "show_score_filter_label": "별점 필터",
  "use_review_keyword_filter_label": "AI 키워드 필터 사용",
  "show_top_filter_menu_label": "카테고리 필터",
  "widget_title": {
    "label": "위젯 제목",
    "placeholder": "위젯 상단에 표시할 제목을 입력해주세요."
  },
  "description_text": {
    "label": "위젯 설명",
    "description": "입력하지 않으면 위젯 제목만 표시합니다.",
    "placeholder": "위젯 제목 하단에 추가할 설명을 입력해주세요."
  },
  "show_mileage_notices": {
    "label": "적립금 공지사항 사용",
    "description": "기본 적립금 기준으로 자동 생성된 공지사항을 위젯에 노출합니다."
  }
}
</i18n>
