<template>
  <div class="ReviewMessagesNthReviewCampaignForm">
    <template v-if="group.id === 'dispatch_recipent'">
      <div class="AppForm__group-field">
        <i18n path="dispatch_recipent.description">
          <AppNumberInput
            v-model="message.dispatch_days"
            name="message[dispatch_days]"
            inline
            :digits="3"
            :invalid="!!group.errors.dispatch_days"
            @blur="$emit('blur', 'dispatch_days')"
            @change="$emit('change', 'dispatch_days')"
          />
        </i18n>
      </div>
      <AppFormError :error="group.errors.dispatch_days" />
      <AppFormHint
        :message="$t('dispatch_recipent.hint', [mileageExiryDays])"
      />
    </template>
    <template v-else-if="group.id === 'dispatch_hour'">
      <div class="AppForm__group-field">
        <i18n path="dispatch_hour.description">
          <AppSelectHour
            v-model="message.dispatch_hour"
            name="message[dispatch_hour]"
            :selectable-hours="SELECTABLE_DISPATCH_HOURS"
          />
        </i18n>
      </div>
      <AppFormHint :message="$t('dispatch_hour.hint')" />
    </template>
    <template v-else-if="group.id === 'mileage'">
      <label v-text="$t('mileage.description', [nthStepReviewMileage])" />
      <AppFormHint :message="$t('mileage.hint')" />
      <AppExternalLinkWithIcon
        :to="pathWithBrandParams('/v2/review/settings/mileage')"
      >
        <AppInlineButton button-style="caption" :label="$t('mileage.link')" />
      </AppExternalLinkWithIcon>
    </template>
    <template v-else-if="group.id === 'biz_message_template_id'">
      <AppButton
        class="AppForm__group-field"
        :label="$t('add_template')"
        @click="clickNewTemplate"
      />
      <div class="AppForm__group-field">
        <ReviewMessagesBizMessageTemplateSelectTable
          :template="message"
          @change="changeSelectedTemplate"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'ReviewMessagesNthReviewCampaignForm',
  props: {
    group: {
      type: Object,
      required: true
    },
    message: {
      type: Object,
      default: () => {}
    },
    mileageExiryDays: {
      type: Number,
      default: null
    },
    nthStepReviewMileage: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapState('bizMessageTemplate', ['templates']),
    ...mapGetters(['pathWithBrandParams'])
  },
  created() {
    this.SELECTABLE_DISPATCH_HOURS = [...Array(12).keys()].map(h => h + 8);
  },
  methods: {
    ...mapActions('bizMessageTemplate', ['clickNewTemplate']),
    changeSelectedTemplate(template) {
      this.$emit('change-template', template);
    }
  }
};
</script>

<i18n locale="ko">
{
  "dispatch_recipent": {
    "description": "n차 리뷰 작성 완료 후 {0} 일 지난 회원",
    "hint": "Tip. n차 리뷰 작성 유도 메시지 발송 완료 후, {0}일이내 작성된 리뷰에 대해서 적립금을 지급합니다."
  },
  "dispatch_hour": {
    "description": "{0} 시에 발송합니다.",
    "hint": "Tip. 쇼핑몰 고객이 여유를 가지고 리뷰를 작성할 수 있는 시간을 설정해보세요."
  },
  "mileage": {
    "description": "작성 시 {0}원 지급",
    "hint": "Tip. 적립금은 [리뷰 설정 - 기본 설정 - 적립금]에서 설정할 수 있습니다.",
    "link": "적립금 설정 메뉴 새탭에서 열기"
  },
  "add_template": "+ 메시지 템플릿 생성"
}
</i18n>
