import REVIEW_SETTINGS_SUPER_ADMIN from '@/constants/REVIEW_SETTINGS_SUPER_ADMIN';
import ReviewVideoReviewsScope from '@/enums/ReviewVideoReviewsScope';

const isMileageCallbackUrlAvailable = ({ state }) =>
  ['none', 'wisa', 'bizhost'].includes(state.session.currentBrand.shop_builder);
const isAddCouponMileageToTotalPriceAvailable = ({ state }) => {
  const { shop_builder } = state.session.currentBrand;
  const { shop_builder_admin_version } = state.session.brandSettings;
  return (
    shop_builder === 'makeshop' ||
    (shop_builder === 'cafe24' && shop_builder_admin_version === 3) ||
    shop_builder === 'none'
  );
};
const isGiveMileageToVideoReviewCentsAvailable = ({ state }) => {
  const { video_reviews_scope } = state.session.reviewSettings;
  return video_reviews_scope === ReviewVideoReviewsScope.ADMIN_AND_POPUP;
};
const isNotShopifyBrand = ({ getters }) => !getters['session/isShopifyBrand'];
const isAccessibleWhenUsingLegacyReviewOptionTypes = ({ state }) =>
  !state.session.reviewSettings.use_product_category_product_type;
const isMakeshopBrand = ({ state }) =>
  state.session.currentBrand.shop_builder === 'makeshop';
const isAccessibleToSeoulVenturesReviewCoesToImportAsVisible = ({ state }) =>
  state.session.brandSettings.api_accessable_to_seoul_ventures;
const isNthStepReviewCampaignEnabled = ({ getters }) =>
  getters['session/isNthStepReviewCampaignEnabled'];

export default [
  {
    id: 'review_settings_manage',
    menuItemId: 'review_settings_manage',
    sections: [
      {
        id: 'writing_conditions',
        groups: [{ id: 'allowed_author_type' }, { id: 'duplicate_review_type' }]
      },
      {
        id: 'permission_settings',
        groups: [
          { id: 'enable_edit' },
          { id: 'enable_delete' },
          { id: 'enable_user_comments' }
        ]
      },
      {
        id: 'advanced',
        groups: [
          { id: 'advanced_tip_box' },
          { id: 'require_confirmation' },
          { id: 'admin_show_entire_message' },
          { id: 'comment_presets' },
          { id: 'use_makeshop_smart_mileage', isAccessible: isMakeshopBrand },
          { id: 'display_write_form_not_allowed_user' },
          { id: 'enable_nonmember_evaluation' },
          { id: 'hide_if' },
          {
            id: 'review_option_types',
            isAccessible: isAccessibleWhenUsingLegacyReviewOptionTypes
          },
          { id: 'category_ids_without_review_contents' },
          { id: 'use_sub_brand_filter_setting', superAdmin: true },
          {
            id: 'seoul_ventures_review_codes_to_import_as_visible',
            superAdmin: true,
            isAccessible: isAccessibleToSeoulVenturesReviewCoesToImportAsVisible
          },
          { id: 'excluding_category_ids' },
          { id: 'excluding_product_ids' },
          { id: 'profanity_filters' },
          { id: 'writing_whitelist_usernames' },
          { id: 'message_dictionary' },
          { id: 'product_name_blacklist' },
          { id: 'request_external_csv' }
        ]
      }
    ]
  },
  {
    id: 'review_settings_popup',
    menuItemId: 'review_settings_popup',
    sections: [
      {
        id: 'write_popup',
        groups: [{ id: 'show_write_popup' }],
        advancedGroups: [
          { id: 'advanced_tip_box' },
          { id: 'popup_period' },
          { id: 'max_popup_count_per_day' },
          { id: 'popup_excluding_category_ids' },
          { id: 'popup_blacklist_usernames' },
          { id: 'popup_direction' },
          {
            id: 'popup_offset_x',
            superAdmin: true
          },
          {
            id: 'popup_offset_y',
            superAdmin: true
          }
        ]
      }
    ]
  },
  {
    id: 'review_settings_engagement',
    menuItemId: 'review_settings_engagement',
    sections: [
      {
        id: 'common',
        groups: [
          { id: 'popup_excluding_category_ids' },
          { id: 'popup_blacklist_usernames' }
        ]
      },
      {
        id: 'message',
        groups: [
          { id: 'message_sending_enabled' },
          { id: 'sending_limit_per_day' }
        ]
      },
      {
        id: 'write_popup',
        groups: [{ id: 'show_write_popup' }],
        advancedGroups: [
          { id: 'advanced_tip_box' },
          { id: 'popup_period' },
          { id: 'max_popup_count_per_day' },
          { id: 'popup_direction' },
          {
            id: 'popup_offset_x',
            superAdmin: true
          },
          {
            id: 'popup_offset_y',
            superAdmin: true
          }
        ]
      },
      {
        id: 'email',
        groups: [{ id: 'email_sending_enabled' }],
        advancedGroups: [
          { id: 'advanced_tip_box' },
          { id: 'email_subject' },
          { id: 'reward_notice_short' },
          { id: 'email_preview' }
        ]
      }
    ]
  },
  {
    id: 'review_settings_design',
    menuItemId: 'review_settings_design',
    sections: [
      {
        id: 'design',
        hideLabel: true,
        groups: [{ id: 'main_color' }],
        advancedGroups: [
          { id: 'advanced_tip_box' },
          { id: 'product_score_filter_color_settings' },
          { id: 'link_color' },
          { id: 'star_color' },
          { id: 'review_write_button_color_settings' },
          { id: 'new_review_popup_button_color_settings' },
          {
            id: 'keyword_filter_tag_color_settings',
            isAccessible: ({ getters }) =>
              getters['session/isListV3InstallationEnabled']
          },
          {
            id: 'review_tag_color_settings',
            isAccessible: ({ getters }) =>
              getters['session/isListV3InstallationEnabled']
          }
        ]
      }
    ]
  },
  {
    id: 'review_settings_sync',
    menuItemId: 'review_settings_sync',
    sections: [
      {
        id: 'review_sync',
        groups: [{ id: 'brand_review_sync' }]
      },
      {
        id: 'order_sync',
        groups: [{ id: 'brand_order_sync' }]
      }
    ]
  },
  {
    id: 'review_settings_widget',
    menuItemId: 'review_settings_widget',
    sections: [
      {
        id: 'widget_display_information',
        groups: [
          { id: 'name_display_type' },
          { id: 'author_display_type' },
          { id: 'show_created_at' }
        ],
        advancedGroups: [
          { id: 'advanced_tip_box' },
          { id: 'show_score' },
          { id: 'show_score_summary' },
          { id: 'enable_review_search' },
          { id: 'use_comments' },
          { id: 'show_spam_report_button' },
          { id: 'show_options' },
          { id: 'mobile_score_icon_type' },
          { id: 'filterable_category_depth' },
          { id: 'show_thumbnail_as_square_on_popup' },
          { id: 'use_after_use_review_badge' },
          { id: 'use_purchase_purpose_badge', superAdmin: true },
          { id: 'allow_custom_font' },
          { id: 'perfect_score', superAdmin: true },
          { id: 'thumbnail_video_autoplay' },
          { id: 'show_store_name_prefix', superAdmin: true },
          { id: 'use_likes_count', superAdmin: true },
          { id: 'use_google_translation', superAdmin: true },
          { id: 'use_renewed_list_style', superAdmin: true },
          { id: 'custom_code_tip_box' },
          { id: 'custom_css_pc' },
          { id: 'custom_css_mobile' },
          { id: 'custom_js_pc' },
          { id: 'custom_js_mobile' }
        ]
      },
      {
        id: 'utilized_url',
        groups: [
          { id: 'utilized_url_tip_box' },
          { id: 'reviews_index_url' },
          { id: 'mall_login_url' },
          { id: 'mall_login_url_mobile' }
        ]
      }
    ]
  },
  {
    id: 'review_settings_mileage',
    menuItemId: 'review_settings_mileage',
    sections: [
      {
        id: 'mileage_condition',
        groups: [
          { id: 'review_point_type' },
          { id: 'give_mileage_expires_after_days' },
          {
            id: 'nth_step_review_mileage_expiry_days',
            isAccessible: isNthStepReviewCampaignEnabled
          },
          { id: 'give_mileage_expires_from_soldout' },
          {
            id: 'mileage_callback_url',
            isAccessible: isMileageCallbackUrlAvailable
          }
        ],
        advancedGroups: [
          { id: 'advanced_tip_box' },
          {
            id: 'add_coupon_mileage_to_total_price',
            isAccessible: isAddCouponMileageToTotalPriceAvailable
          },
          { id: 'limit_give_mileage_each_sub_order' },
          { id: 'give_mileage_exceptions' },
          { id: 'auto_mileage_required_review_option_type_ids' },
          {
            id: 'auto_mileage_excluding_category_ids',
            isAccessible: isNotShopifyBrand
          },
          { id: 'auto_mileage_excluding_product_ids' },
          {
            id: 'auto_mileage_excluding_user_grade_ids',
            isAccessible: isNotShopifyBrand
          }
        ]
      },
      {
        id: 'mileage_amount',
        groups: [{ id: 'basic_mileage_criteria' }],
        advancedGroups: [
          { id: 'advanced_tip_box' },
          { id: 'action_for_insufficient_review_length_to_get_mileage' },
          { id: 'auto_mileage_use_valid_review_message_length' },
          { id: 'mileage_first_review' },
          { id: 'give_mileage_nth_photo_reviews' },
          {
            id: 'give_mileage_to_video_review_cents',
            isAccessible: isGiveMileageToVideoReviewCentsAvailable
          },
          { id: 'give_mileage_to_best_review_cents' },
          {
            id: 'nth_step_review_additional_mileage_cents',
            isAccessible: isNthStepReviewCampaignEnabled
          },
          { id: 'manual_mileage_max_amount_cents' },
          {
            id: 'auto_mileage_user_grade_bonuses',
            isAccessible: isNotShopifyBrand
          },
          { id: 'auto_mileage_by_prices' },
          { id: 'give_mileage_to_include_link_review_cents' }
        ]
      }
    ]
  },
  ...REVIEW_SETTINGS_SUPER_ADMIN
];
