<template>
  <AppDataSection :title="$t('title')">
    <div
      class="ReviewMessagesNthStepReviewCampaignCategorySettings__button-wrapper"
    >
      <AppButton
        :label="$t('app.settings')"
        @click="openCategorySettingsDialog"
      />
    </div>
    <div>
      <label v-text="$t('included_category')" />
      <span>-</span>
    </div>
    <div>
      <label v-text="$t('excluded_category')" />
      <span>-</span>
    </div>
  </AppDataSection>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ReviewMessagesNthStepReviewCampaignCategorySettings',
  methods: {
    ...mapActions('dialog', ['openDialog']),
    openCategorySettingsDialog() {
      this.openDialog('ReviewMessagesNthStepReviewCampaignCategoryDialog');
    }
  }
};
</script>

<style>
.ReviewMessagesNthStepReviewCampaignCategorySettings__button-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>

<i18n locale="ko">
{
  "title": "발송 대상 카테고리",
  "included_category": "포함 카테고리: ",
  "excluded_category": "제외 카테고리: "
}
</i18n>
