<template>
  <AppModalForm
    :title="$t('title')"
    :form-props="{ formStyle: 'wide', ...formProps }"
    v-on="formEvents"
  />
</template>

<script>
import DialogFormView from '@/mixins/DialogFormView';

export default {
  name: 'ReviewMessagesNthStepReviewCampaignCategoryDialog',
  mixins: [DialogFormView]
};
</script>

<i18n locale="ko">
{
  "title": "발송 대상 상품 카테고리 설정"
}
</i18n>
