<template>
  <div class="InstallRequestRenewalDirectionDialogReviewBoard">
    InstallRequestRenewalDirectionDialogReviewBoard
  </div>
</template>

<script>
export default {
  name: 'InstallRequestRenewalDirectionDialogReviewBoard'
};
</script>
